import React, { Component } from "react";
import { unauthorize } from "../actions";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { BACKEND_URL } from "../constants";

class RidgesTable extends Component {

  constructor(props) {
    super(props);
    this.state = initState;
  }

  componentDidMount() {
    this.getData();
    this.getProductTypes();
    this.getExistingMaterialSeries();
  }

  componentWillUnmount() {
    this.setState(initState);
  }

    getData = () => {
      const that = this;
      fetch(BACKEND_URL + "/ridges", {
        credentials: "include"
      })
        .then((res) => {
          if (res.status === 403 || res.status === 401) {
            throw Error("Response status code " + res.status);
          }
          return res;
        })
        .then(res => res.json())
        .then(res => {
          that.setState({
            ridges: res,
          });
        })
        .catch(err => {
          console.error(err);
          that.props.unauthorize();
        });
    }

    getProductTypes = () => {
      const that = this;
      fetch(BACKEND_URL + "/ridges/productTypes", {
        credentials: "include"
      })
        .then((res) => {
          if (res.status === 403 || res.status === 401) {
            throw Error("Response status code " + res.status);
          }
          return res;
        })
        .then(res => res.json())
        .then(res => {
          that.setState({
            productTypes: res,
          });
        })
        .catch(err => {
          console.error(err);
          that.props.unauthorize();
        });
    }

    getExistingMaterialSeries = () => {
      const that = this;
      fetch(BACKEND_URL + "/ridges/existingMaterialSeries", {
        credentials: "include"
      })
        .then((res) => {
          if (res.status === 403 || res.status === 401) {
            throw Error("Response status code " + res.status);
          }
          return res;
        })
        .then(res => res.json())
        .then(res => {
          that.setState({
            existingMaterialSeries: res,
          });
        })
        .catch(err => {
          console.error(err);
          that.props.unauthorize();
        });
    }

    saveValue = (id, value) => {
      const that = this;
      fetch(BACKEND_URL + "/ridges/update-value", {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          "id": id,
          "value": value
        }),
        credentials: "include"
      })
        .then((res) => {
          if (res.status === 403 || res.status === 401) {
            throw Error("Response status code " + res.status);
          }
        })
        .then(() => {
          that.getData();
        })
        .catch(err => {
          console.error(err);
          that.props.unauthorize();
        });
    }

    removeValue = (id) => {
      const that = this;
      fetch(BACKEND_URL + "/ridges/" + id, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json"
        },
        credentials: "include"
      })
        .then((res) => {
          if (res.status === 403 || res.status === 401) {
            throw Error("Response status code " + res.status);
          }
        })
        .then(() => {
          that.getData();
          this.getExistingMaterialSeries();
        })
        .catch(err => {
          console.error(err);
          that.props.unauthorize();
        });
    }

    saveNewRidge = () => {
      const that = this;
      fetch(BACKEND_URL + "/ridges/create", {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify(this.state.newRidge),
        credentials: "include"
      })
        .then((res) => {
          if (res.status === 403 || res.status === 401) {
            throw Error("Response status code " + res.status);
          }
        })
        .then(() => {
          that.getData();
          this.getExistingMaterialSeries();
        })
        .catch(err => {
          console.error(err);
          that.props.unauthorize();
        });    
    }

    handleChange = (index, value) => {
      const ridges = this.state.ridges;
      ridges[index].value = value;
      this.setState({
        ridges: ridges
      });
    }

    handleNewRidgeProductTypeChange = (event) => {
      const newRidge = this.state.newRidge;
      newRidge.productType = event.target.value;
      this.setState({
        newRidge: newRidge
      });
    }

    handleNewRidgeMaterialSeriesChange = (event) => {
      const newRidge = this.state.newRidge;
      newRidge.materialSeries = event.target.value;
      this.setState({
        newRidge: newRidge
      });
    }

    handleNewRidgeNumberOfPagesChange = (event) => {
      const newRidge = this.state.newRidge;
      newRidge.numberOfPages = event.target.value;
      this.setState({
        newRidge: newRidge
      });
    }

    handleNewRidgeValueChange = (event) => {
      const newRidge = this.state.newRidge;
      newRidge.value = event.target.value;
      this.setState({
        newRidge: newRidge
      });
    }

    render() {
      if (!this.props.authorized) {
        return (
          <Redirect to="/login" />
        );
      }
      return (<div>
        <div className="tablePageTopMenu">
          <button onClick={this.logout}>Wyloguj</button>
          <button onClick={() => this.props.history.push("/")}>Powrót</button>
          <div className="grow" />
        </div>

        <div className="ridgesTable">
          <table>
            <tbody>
              <tr>
                <td colSpan="2">Dodaj nowy wpis</td>
              </tr>
              <tr>
                <td>
                  <select value={this.state.newRidge.productType} onChange={this.handleNewRidgeProductTypeChange}>
                    <option value="">Typ produktu</option>
                    {this.state.productTypes.map((element, index) => (
                      <option key={index} value={element.productType}>{element.productTypeDesc}</option>
                    ))}
                  </select>
                </td>
                <td>
                  <input value={this.state.newRidge.materialSeries} onChange={this.handleNewRidgeMaterialSeriesChange} list="existingMaterialSeries" placeholder="Seria materiału"/>
                  <datalist id="existingMaterialSeries">
                    {this.state.existingMaterialSeries.map((element, index) => (
                      <option key={index} value={element}/>
                    ))}
                  </datalist>
                </td>
                <td>
                  <input value={this.state.newRidge.numberOfPages} onChange={this.handleNewRidgeNumberOfPagesChange} type="text" placeholder="Liczba stron/rozkładówek"/>
                </td>
                <td>
                  <input value={this.state.newRidge.value} onChange={this.handleNewRidgeValueChange} type="text" placeholder="Wartość grzbietu"/>
                </td>
                <td>
                  <button onClick={() => this.saveNewRidge()}>Zapisz</button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div className="ridgesTable">
          <table>
            <thead>
              <tr>
                <td>Produkt</td>
                <td>Seria</td>
                <td>Liczba stron</td>
                <td>Wartość</td>
                <td></td>
              </tr>
            </thead>
            <tbody>
              {this.state.ridges.map((element, index) => (
                <tr key={index}>
                  <td>{element.productTypeDesc}</td>
                  <td>{element.materialSeries}</td>
                  <td>{element.numberOfPages}</td>
                  <td>
                    <input type="text" name={"ridge" + element.id} value={this.state.ridges[index].value} onChange={(event) => this.handleChange(index, event.target.value)} />
                  </td>
                  <td>
                    <button onClick={() => this.saveValue(element.id, element.value)}>Zapisz</button>
                                    &nbsp;&nbsp;&nbsp;
                    <button onClick={() => this.removeValue(element.id)}>Usuń</button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>);
    }
}

const initState = {
  ridges: [],
  productTypes: [],
  existingMaterialSeries: [],
  newRidge: {
    productType: "",
    materialSeries: "",
    numberOfPages: "",
    value: ""
  }
};

const mapStateToProps = (state) => {
  return {
    authorized: state.authorized
  };
};

const mapDispatchToProps = { unauthorize };

export default connect(mapStateToProps, mapDispatchToProps)(RidgesTable);
