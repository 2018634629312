export const unauthorize = () => ({
  type: "AUTHORIZATION",
  value: false
});

export const authorize = () => ({
  type: "AUTHORIZATION",
  value: true
});

export const storeCoversTablePage = (coversTablePage) => ({
  type: "STORE_COVERS_TABLE_PAGE",
  quantity: coversTablePage.quantity,
  counter: coversTablePage.counter,
  offset: coversTablePage.offset
});

export const storeCrystalBoardsTablePage = (crystalBoardsPage) => ({
  type: "STORE_CRYSTAL_BOARDS_TABLE_PAGE",
  quantity: crystalBoardsPage.quantity,
  counter: crystalBoardsPage.counter,
  offset: crystalBoardsPage.offset
});

export const storeCanvasesTablePage = (canvasesPage) => ({
  type: "STORE_CANVASES_TABLE_PAGE",
  quantity: canvasesPage.quantity,
  counter: canvasesPage.counter,
  offset: canvasesPage.offset
});