import React, {Component} from "react";
import {HashRouter, Route} from "react-router-dom";
import NewPanelRawOrderDataTable from "./NewPanelRawOrderDataTable";

class NewPanelRawOrderDataPage extends Component {
  render() {
    return (<HashRouter>
      <Route exact path="/newPanelRawOrderData" component={NewPanelRawOrderDataTable}/>
    </HashRouter>);
  }
}

export default NewPanelRawOrderDataTable;