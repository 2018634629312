import React, {Component} from "react";


class NewPaging extends Component {

    paging = () => {
      const size = this.props.size;
      const page = this.props.page;
      const totalElements = this.props.totalElements;
      const totalPages = this.props.totalPages;
      const pageLabel = <div>
            strona {page + 1} / {totalPages}
      </div>;
    //   if (offset + quantity + 1 > counter) {
    //     return <div>
    //       {page + 1} - {size} / {counter}
    //       {pageLabel}
    //     </div>;
    //   }
      return <div>
        {1 + (size * page)} - {size + (size * page)} / {totalElements}
        {pageLabel}
      </div>;
    }

    render() {
      return (
        <div>
          <div className="info">
            {this.paging()}
          </div>
        </div>
      );
    }
}

export default NewPaging;