import React, {Component} from "react";
import {HashRouter, Route} from "react-router-dom";
import CoversTable from "./CoversTable";
import EditCover from "./EditCover";
import BatchColors from "./BatchColors";

class CoversPage extends Component {

  render() {
    return (<HashRouter>
      <Route exact path="/covers" component={CoversTable}/>
      <Route path="/covers/edit/:id" component={EditCover}/>
      <Route path="/covers/batchColors" component={BatchColors}/>
    </HashRouter>
    );
  }
}


export default CoversPage;