import React, {Component} from "react";
import {Redirect} from "react-router-dom";
import {unauthorize} from "../actions";
import {connect} from "react-redux";
import {BACKEND_URL} from "../constants";
import CompleteButton from "../components/CompleteButton";
import PrevButton from "../components/PrevButton";
import NextButton from "../components/NextButton";
import Paging from "../components/Paging";
import QuantitySelect from "../components/QuantitySelect";
import ReactModal from "react-modal";

class PersonalizationTable extends Component {

  constructor(props) {
    super(props);
    this.state = initState;
    this.setCommentEditText = this.setCommentEditText.bind(this);
  }
    
    searchInputKeyboardShortcut = function (e) {
      if (e.key === "/") {
        document.getElementById("searchInput").value = "";
        document.getElementById("searchInput").focus();
      }
    }

    componentDidMount() {
      this.getData();
      setInterval(
        function () {
          this.getData();
        }.bind(this),
        60 * 1000
      );
      window.addEventListener(
        "keyup",
        this.searchInputKeyboardShortcut, 
        false
      );
    }

    componentWillUnmount() {
      window.removeEventListener("keypress", this.searchInputKeyboardShortcut);
    }

    getData = () => {
      const that = this;
      const quantity = this.state.quantity;
      const offset = this.state.offset;
      const searchPhrase = this.state.searchPhrase;
      const showArchived = this.state.showArchived;
      fetch(BACKEND_URL + "/personalization?quantity=" + quantity +
            "&offset=" + offset +
            (searchPhrase ? "&orderNumberPattern=" + searchPhrase : "") +
            (showArchived ? "&showArchived=true" : ""), {
        credentials: "include"
      })
        .then((res) => {
          if (res.status === 403 || res.status === 401) {
            throw Error("Response status code " + res.status);
          }
          return res;
        })
        .then(res => res.json())
        .then(res => {
          that.setState({
            personalization: res.data,
            counter: res.counter
          });
        })
        .catch(err => {
          console.error(err);
          that.props.unauthorize();
        });
    }

    getOrderUrl = element => {
      return "https://panel.crystal-albums.pl/laborders/orders/edit/order_id/" + element.orderNumber;
    }

    getAlbumOrderUrl = (element) => {
      return "https://panel.crystal-albums.pl/laborders/albumorders/details/order_id/" +
            element.orderNumber + "/album_order_config_id/" + element.albumOrderNumber;
    }

    setQuantity = (event) => {
      let value = event.target.value;
      this.setState({
        quantity: parseInt(value), //FIXME
        offset: 0
      }, () => this.getData());
    }

    logout = () => {
      let that = this;
      fetch(BACKEND_URL + "/logout", {credentials: "include"})
        .then(() => that.props.unauthorize())
        .catch((err) => console.log("Logout error", err));
    }


    switchShowArchived = () => {
      this.setState({
        showArchived: !this.state.showArchived,
        offset: 0
      }, () => this.getData());
    }

    search = (event) => {
      const phrase = event.target.value;
      this.setState({
        searchPhrase: phrase,
        offset: 0
      }, () => this.getData());
    }

    markIfEmpty = value => {
      return value ? value : <div className="redBox"/>;
    }

    getOrNoData = (element, label) => {
      return <div className={element ? "" : "personalizationPropertyNotFound"}>
        {label}:&nbsp;{element ? element : "nie podano"}
      </div>;
    }

    showPersonalizationProperties = (index) => {
      const el = document.getElementById("personalizationProperties" + index);
      el.classList.toggle("show");
      const elButton = document.getElementById("showHideButton" + index);
      elButton.innerText = elButton.classList.contains("show") ? "Rozwiń" : "Zwiń";
    }

    showAll = () => {
      const els = document.querySelectorAll("[id^=\"personalizationProperties\"]");
      els.forEach((el) => {
        if (!el.classList.contains("show")) {
          el.classList.add("show");
        }
      });

      const elsButtons = document.querySelectorAll("[id^=\"showHideButton\"]");
      elsButtons.forEach((el) => {
        el.innerText = "Zwiń";
      });
    }

    hideAll = () => {
      const els = document.querySelectorAll("[id^=\"personalizationProperties\"]");
      els.forEach((el) => {
        if (el.classList.contains("show")) {
          el.classList.remove("show");
        }
      });

      const elsButtons = document.querySelectorAll("[id^=\"showHideButton\"]");
      elsButtons.forEach((el) => {
        el.innerText = "Rozwiń";
      });
    }

    mapMediasToButtons = (media, index) => {
      return (
        <div key={index}><a href={BACKEND_URL + "/personalization/file/" + media.id}>Pobierz załącznik
                - {media.parameterName}</a></div>);
    }

    mapElementToRow = (element, index) => {
      const completeButton = this.state.showArchived ? "" :
        <CompleteButton id={element.id} productType={element.productType} reloadData={this.getData}/>;
      let backSection;
      if (element.logotype != null && element.logotype) {
        backSection = (<div>
          <br/>
          <div>Tył:</div>
          <div>
            <div className={element.logotype ? "" : "personalizationPropertyNotFound"}>
                        Logotyp z tyłu
                        okładki:&nbsp;{element.logotype ? "tak" : "nie"}
            </div>
            {this.getOrNoData(element.logotypePosition, "Pozycja logotypu na okładce")}
            <div className={element.logotypeOverlay ? "" : "personalizationPropertyNotFound"}>
                        Biały poddruk pod nadrukiem
                        UV:&nbsp;{element.logotypeOverlay != null ? (element.logotypeOverlay ? "tak" : "nie") : "nie podano"}
            </div>
          </div>
        </div>);
      } else {
        backSection = "";
      }

      let mediaSection;
      if (element.medias != null && element.medias && Array.isArray(element.medias) && element.medias.length) {
        mediaSection = <div>
          <br/>
          <div>Załączniki:</div>
          <div>
            {element.medias.map((media, index) => this.mapMediasToButtons(media, index))}
          </div>
        </div>;
      } else {
        mediaSection = "";
      }

      return (
        <tr key={index} className={element.status === "Poprawka" ? "inCorrection" : null}>
          <td width="5%" style={{background: element.batchColor}} className={element.archived ? "archivedRow" : null}> {/*FIXME width*/}
            <a href={this.getOrderUrl(element)} target="_blank" rel="noopener noreferrer"
              className="tableLink">
              {element.orderNumber}
            </a>
          </td>
          <td width="5%" className={element.archived ? "archivedRow" : null}> {/*FIXME width*/}
            <a href={this.getAlbumOrderUrl(element)} target="_blank" rel="noopener noreferrer"
              className="tableLink">
              {element.albumOrderNumber}
            </a>
          </td>
          <td width="50%">
            <div>
              {element.suborderName}
            </div>
            <div className="coversComment">{element.comment}</div>
            <div className="personalizationProperties" id={"personalizationProperties" + index}>
              <div>Przód:</div>
              <div className="frontPersonalization">
                <div className="frontPersonalizationLeft">
                  {this.getOrNoData(element.productType, "Typ produktu")}
                  {this.getOrNoData(element.materialSeries, "Typ skóry")}
                  {this.getOrNoData(element.format, "Format")}
                  {this.getOrNoData(element.personalizationMethod, "Metoda personalizacji")}
                  {this.getOrNoData(element.personalizationPosition, "Pozycja personalizacji")}
                </div>
                <div className="frontPersonalizationLeftRight">
                  {this.getOrNoData(element.engraverFont, "Font do graweru")}
                  {this.getOrNoData(element.personalizationFirstLine, "Tekst pierwszej linii")}
                  {this.getOrNoData(element.personalizationSecondLine, "Tekst drugiej linii")}
                  {this.getOrNoData(element.personalizationThirdLine, "Tekst trzeciej linii")}
                  {this.getOrNoData(element.personalizationTemplate, "Wzór")}
                  {this.getOrNoData(element.templateType, "Typ wzoru")}
                  {this.getOrNoData(element.personalizationOverlay, "Biały poddruk pod nadrukiem UV")}
                  {this.getOrNoData(element.personalizationOverprintText, "Kolor nadruku")}
                  {this.getOrNoData(element.font, "Czcionka")}
                  {this.getOrNoData(element.text, "Tekst")}
                  {this.getOrNoData(element.preparedTemplate, "Wzór: numer z pola")}
                  {this.getOrNoData(element.envelopeColor, "Kolor teczki")}
                  <br/>
                  {this.getOrNoData(element.pendriveMethod, "Pendrive - metoda")}
                  {this.getOrNoData(element.pendriveType, "Pendrive - typ")}
                  {this.getOrNoData(element.pendriveFont, "Pendrive - font")}
                  {this.getOrNoData(element.pendriveText, "Pendrive - tekst")}
                  {this.getOrNoData(element.pendriveMethodSecondSide, "Pendrive - metoda dla II strony")}
                  {this.getOrNoData(element.pendriveTypeSecondSide, "Pendrive - typ dla II strony")}
                  {this.getOrNoData(element.pendriveFontSecondSide, "Pendrive - font dla II strony")}
                  {this.getOrNoData(element.pendriveTextSecondSide, "Pendrive - tekst dla II strony")}
                </div>
              </div>
              {backSection}
              {mediaSection}
            </div>
          </td>
          <td width="10%">{this.markIfEmpty(element.productType)}</td>
          <td width="5%">{this.markIfEmpty(element.format)}</td>
          <td width="10%">{this.markIfEmpty(element.personalizationType)}</td>
          <td width="5%">{element.quantity}</td>
          <td width="12%">
            <div style={{display: "flex"}}>
              <button onClick={() => this.handleOpenModal(element.id, element.comment, element.productType)}>Edycja komentarza</button>
              <div className="personalizationTableButtons">
                <div className="printButton" onClick={() => this.showPersonalizationProperties(index)}>
                  <div className="printButtonInner" id={"showHideButton" + index}>
                                    Rozwiń
                  </div>
                </div>
                {completeButton}
              </div>
            </div>
          </td>
        </tr>);
    }

    setOffsetAndRefresh = (newOffset) => {
      this.setState({
        offset: newOffset,
      }, () => this.getData());
    }
    
    asd = () => {
      console.log("asd");
    }

    saveComment = () => {
      this.saveCommentWithClearingOrNot(false);
    }

    saveCommentWithClearingOrNot = (clear) => {
      let that = this;
      fetch(BACKEND_URL + "/personalization/comment/" + this.state.commentEditId + "/" + this.state.commentEditProductType, {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          comment: this.state.commentEditText
        }),
        credentials: "include"
      })
        .then((res) => {
          if (res.status === 403 || res.status === 401) {
            throw Error("Response status code " + res.status);
          }
        })
        .then((res) => {
          if (clear === true) {
            this.setState({ 
              showModal: false,
              commentEditId: initState.commentEditId,
              commentEditText: initState.commentEditText,
              commentEditProductType: initState.commentEditProductType
            }, () => this.getData());
          }
        })
        .catch(err => {
          console.error(err);
          that.props.unauthorize();
        });
    }

    handleOpenModal = (id, comment, productType) => {
      this.setState({ showModal: true,
        commentEditId: id,
        commentEditText: comment,
        commentEditProductType: productType});
    }

    handleCloseModal = () => {
      this.setState({ 
        showModal: false,
        commentEditId: initState.commentEditId,
        commentEditText: initState.commentEditText,
        commentEditProductType: initState.commentEditProductType
      }, () => this.getData());
    }

    handleSaveCloseModal = () => {
      this.saveCommentWithClearingOrNot(true);
    }

    setCommentEditText = (event) => {
      this.setState({
        commentEditText: event.target.value
      });
    }

    render() {
      if (!this.props.authorized) {
        return (
          <Redirect to="/login"/>
        );
      }
      const quantity = this.state.quantity;
      const showArchivedButton = <button
        onClick={this.switchShowArchived}>{this.state.showArchived ? "Produkcja" : "Archiwum"}</button>;
      return (
        <div>
          <ReactModal isOpen={this.state.showModal} contentLabel="Edycja komentarza">
            <div style={{display: "grid", placeItems: "center"}}>
              <div>
                            Edycja komentarza
              </div>
              <div>
                <textarea rows="5" cols="150" value={this.state.commentEditText}
                  onChange={this.setCommentEditText}/>
              </div>
              <div>
                <button style={{width: "200px", height: "50px", margin: "10px"}} onClick={this.handleCloseModal}>ZAMKNIJ</button>
                <button style={{width: "200px", height: "50px", margin: "10px"}} onClick={this.saveComment}>ZAPISZ</button>
                <button style={{width: "200px", height: "50px", margin: "10px"}} onClick={this.handleSaveCloseModal}>ZAPISZ I ZAMKNIJ</button>
              </div>
            </div>
          </ReactModal>
          <div className="tablePageTopMenu">
            <button onClick={this.logout}>Wyloguj</button>
            <button onClick={() => this.props.history.push("/")}>Powrót</button>
            {showArchivedButton}
            <input type="text" value={this.state.searchPhrase} onChange={this.search} placeholder="Wyszukaj"
              className="searchInput" id="searchInput" ref={this.textInput}/>
            <div className="grow"/>
            <button onClick={this.hideAll}>Zwiń wszystkie</button>
            <button onClick={this.showAll}>Rozwiń wszystkie</button>
            <Paging quantity={quantity} offset={this.state.offset} counter={this.state.counter}/>
            <PrevButton quantity={quantity} offset={this.state.offset} callback={this.setOffsetAndRefresh}/>
            <NextButton quantity={quantity} offset={this.state.offset} counter={this.state.counter}
              callback={this.setOffsetAndRefresh}/>
          </div>
          <div className="coversTable">
            <table>
              <thead>
                <tr>
                  <td>Nr zlecenia</td>
                  <td>Nr podzlecenia</td>
                  <td>Nazwa</td>
                  <td>Typ</td>
                  <td>Format</td>
                  <td>Typ personalizacji</td>
                  <td>Ilość</td>
                  <td>Opcje</td>
                </tr>
              </thead>
              <tbody>
                {this.state.personalization.map((element, index) => this.mapElementToRow(element, index))}
              </tbody>
            </table>
          </div>
          <div className="tablePageBottomMenu">
            <div className="grow"/>
            <QuantitySelect counter={this.state.counter} setQuantity={this.setQuantity}/>
          </div>
        </div>
      );
    }
}

const initState = {
  personalization: [],
  quantity: 10,
  offset: 0,
  searchPhrase: "",
  counter: 0,
  showArchived: false,
  showModal: false,
  commentEditId: undefined,
  commentEditText: "",
  commentEditProductType: "",
};

const mapStateToProps = (state) => {
  return {
    authorized: state.authorized
  };
};

const mapDispatchToProps = {unauthorize};

export default connect(mapStateToProps, mapDispatchToProps)(PersonalizationTable);