import React, {Component} from "react";
import {unauthorize, authorize} from "../actions";
import {connect} from "react-redux";
import {BACKEND_URL} from "../constants";
import {Redirect} from "react-router-dom";
import logo from "./../images/logo.png";

class LoginPage extends Component {

  constructor(props) {
    super(props);
    this.state = initState;
  }

  componentWillUnmount() {
    this.setState(initState);
  }

    login = () => {
      let that = this;
      fetch(BACKEND_URL + "/login", {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          "username": that.state.username,
          "password": that.state.password
        }),
        credentials: "include"
      })
        .then(res => {
          that.setState(initState);
          return res;
        })
        .then(res => {
          if (res.status === 403 || res.status === 401) {
            throw Error("Response status code " + res.status);
          }
          return res;
        })
        .then(() => that.props.authorize())
        .catch((err) => console.log("Login error", err));
    }

    handleGenericChange = (field, value) => {
      this.setState(Object.assign({}, this.state.use, {
        [field]: value
      })
      );
    }

    render() {
      if (this.props.authorized) {
        return (
          <Redirect to="/"/>
        );
      }
      let button = (<button onClick={this.login} className="loginButton">Zaloguj</button>);
      return (
        <div className="loginContainer">
          <div className="loginForm">
            <div className="loginTitle">
              <img src={logo} alt="Sticker of Avalone logo"/><br/>
                        Logowanie
            </div>
            <br/>
            <label htmlFor="login">Użytkownik</label>
            <input type="text" value={this.state.username}
              onChange={(event) => this.handleGenericChange("username", event.target.value)}/>
            <label htmlFor="login">Hasło</label>
            <input type="password" value={this.state.password}
              onChange={(event) => this.handleGenericChange("password", event.target.value)}/>
            <div className="loginButtons">
              {button}
            </div>
          </div>
        </div>
      );
    }
}

const initState = {
  username: "",
  password: ""
};

const mapStateToProps = (state) => {
  return {
    authorized: state.authorized
  };
};

const mapDispatchToProps = {unauthorize, authorize};

export default connect(mapStateToProps, mapDispatchToProps)(LoginPage);