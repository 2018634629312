/* eslint-disable indent */
export const tablePage = (state = initState, action) => {
  switch (action.type) {
    case "STORE_COVERS_TABLE_PAGE":
      return Object.assign({}, state, {
        ...state,
        coversTablePage: {
          quantity: action.quantity != null ? action.quantity : state.coversTablePage.quantity,
          counter: action.counter != null ? action.counter : state.coversTablePage.counter,
          offset: action.offset != null ? action.offset : state.coversTablePage.offset
        }
      });
    case "STORE_CRYSTAL_BOARDS_TABLE_PAGE":
      return Object.assign({}, state, {
        ...state,
        crystalBoardsTablePage: {
          quantity: action.quantity != null ? action.quantity : state.crystalBoardsTablePage.quantity,
          counter: action.counter != null ? action.counter : state.crystalBoardsTablePage.counter,
          offset: action.offset != null ? action.offset : state.crystalBoardsTablePage.offset
        }
      });
    case "STORE_CANVASES_TABLE_PAGE":
      return Object.assign({}, state, {
        ...state,
        canvasesTablePage: {
          quantity: action.quantity != null ? action.quantity : state.canvasesTablePage.quantity,
          counter: action.counter != null ? action.counter : state.canvasesTablePage.counter,
          offset: action.offset != null ? action.offset : state.canvasesTablePage.offset
        }
      });
    default:
      return state;
  }
};

const initState = {
  coversTablePage: {
    quantity: 10,
    counter: 0,
    offset: 0
  },
  crystalBoardsTablePage: {
    quantity: 10,
    counter: 0,
    offset: 0
  },
  canvasesTablePage: {
    quantity: 10,
    counter: 0,
    offset: 0
  }
};
