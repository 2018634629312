import React, { Component } from "react";
import { unauthorize } from "../actions";
import { connect } from "react-redux";
import { BACKEND_URL } from "../constants";
import { Redirect } from "react-router-dom";

class BatchColors extends Component {
  constructor(props) {
    super(props);
    this.state = initState;
  }

  componentDidMount() {
    this.getColors();
    this.getRanges();
  }

  componentWillUnmount() {
    this.setState(initState);
  }

  getColors = () => {
    const that = this;
    fetch(BACKEND_URL + "/batch_color/get_all_colors", {
      credentials: "include",
    })
      .then((res) => {
        if (res.status === 403 || res.status === 401) {
          throw Error("Response status code " + res.status);
        }
        return res;
      })
      .then((res) => res.json())
      .then((res) => {
        that.setState({
          colors: res,
        });
      })
      .catch((err) => {
        console.error(err);
        that.props.unauthorize();
      });
  };

  handleNewColorLabelChange = (event) => {
    const newColor = this.state.newColor;
    newColor.label = event.target.value;
    this.setState({
      newColor: newColor,
    });
  };

  handleNewColorColorChange = (event) => {
    const newColor = this.state.newColor;
    newColor.color = event.target.value;
    this.setState({
      newColor: newColor,
    });
  };
  
  handleOptionalR1MaxOrderNumberChange = (event) => {
    this.setState({
      optionalR1MaxOrderNumber:  event.target.value,
    });
  };

  saveNewColor = () => {
    const that = this;
    fetch(BACKEND_URL + "/batch_color/add_color", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(this.state.newColor),
      credentials: "include",
    })
      .then((res) => {
        if (res.status === 403 || res.status === 401) {
          throw Error("Response status code " + res.status);
        }
      })
      .then(() => {
        that.getColors();
      })
      .catch((err) => {
        console.error(err);
        that.props.unauthorize();
      });
  };

  removeColor = (id) => {
    const that = this;
    fetch(BACKEND_URL + "/batch_color/delete_color/" + id, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
    })
      .then((res) => {
        if (res.status === 403 || res.status === 401) {
          throw Error("Response status code " + res.status);
        }
      })
      .then(() => {
        that.getColors();
        that.getRanges();
      })
      .catch((err) => {
        console.error(err);
        that.props.unauthorize();
      });
  };

  getRanges = () => {
    const that = this;
    fetch(BACKEND_URL + "/batch_color/get_all_ranges", {
      credentials: "include",
    })
      .then((res) => {
        if (res.status === 403 || res.status === 401) {
          throw Error("Response status code " + res.status);
        }
        return res;
      })
      .then((res) => res.json())
      .then((res) => {
        that.setState({
          ranges: res,
        });
      })
      .catch((err) => {
        console.error(err);
        that.props.unauthorize();
      });
  };

  handleNewRangeFromNumberChange = (event) => {
    const newRange = this.state.newRange;
    newRange.fromNumber = event.target.value;
    this.setState({
      newRange: newRange,
    });
  };

  handleNewRangeToNumberChange = (event) => {
    const newRange = this.state.newRange;
    newRange.toNumber = event.target.value;
    this.setState({
      newRange: newRange,
    });
  };

  handleNewRangeColorIdChange = (event) => {
    const newRange = this.state.newRange;
    newRange.colorId = event.target.value;
    this.setState({
      newRange: newRange,
    });
  };

  handleNewRangeColorIdChange = (event) => {
    const newRange = this.state.newRange;
    newRange.colorId = event.target.value;
    this.setState({
      newRange: newRange,
    });
  };


  saveNewRange = () => {
    const that = this;
    fetch(BACKEND_URL + "/batch_color/add_range", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(this.state.newRange),
      credentials: "include",
    })
      .then((res) => {
        if (res.status === 403 || res.status === 401) {
          throw Error("Response status code " + res.status);
        }
      })
      .then(() => {
        that.getRanges();
      })
      .catch((err) => {
        console.error(err);
        that.props.unauthorize();
      });
  };

  removeRange = (id) => {
    const that = this;
    fetch(BACKEND_URL + "/batch_color/delete_range/" + id, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
    })
      .then((res) => {
        if (res.status === 403 || res.status === 401) {
          throw Error("Response status code " + res.status);
        }
      })
      .then(() => {
        that.getRanges();
      })
      .catch((err) => {
        console.error(err);
        that.props.unauthorize();
      });
  };

  getColorById = (id) => {
    const colors = this.state.colors;
    const selectetColor = colors.find(function (color) {
      return color.id === id;
    });
    if (selectetColor != null) {
      return <div>{selectetColor.label}</div>;
    } else {
      return <div />;
    }
  };

  recalculate = () => {
    const that = this;
    fetch(BACKEND_URL + "/batch_color/recalculate?orderNumber=" + this.state.optionalR1MaxOrderNumber, {
      method: "POST",
      credentials: "include",
    })
      .then((res) => {
        if (res.status === 403 || res.status === 401) {
          throw Error("Response status code " + res.status);
        }
      })
      .then(() => {
        that.getRanges();
      })
      .catch((err) => {
        console.error(err);
        that.props.unauthorize();
      });
  }

  render() {
    if (!this.props.authorized) {
      return <Redirect to="/login" />;
    }
    return (
      <div>
        <div className="tablePageTopMenu">
          <button onClick={this.logout}>Wyloguj</button>
          <button onClick={() => this.props.history.push("/covers")}>
            Powrót
          </button>
        </div>

        <div className="ridgesTable">
          <table>
            <tbody>
              <tr>
                <td colSpan="2">Przelicz zakresy</td>
              </tr>
              <tr>
                <td>
                  <input size="38"
                    value={this.state.optionalR1MaxOrderNumber}
                    onChange={this.handleOptionalR1MaxOrderNumberChange}
                    placeholder="Opcjonalny numer DO dla zakresu z kolorem Red_1"
                  />
                </td>
                <td>
                  <button onClick={() => this.recalculate()}>Przelicz zakresy</button>
                </td>
              </tr>
              <tr>
                <td colSpan="2">Przeliczanie usuwa wszystkie istniejące zakresy dla kolorów Red_1, Red_2, Yellow_1, Yellow_2, Green_1, Green_2 oraz Bufor, a następnie tworzy nowe wyliczając numery OD i DO dla każdego. Jeśli nie podasz opcjonalnego numeru DO dla zakresu Red_1, zakres zostanie wyliczony tak samo jak pozostałe, czyli maksymalnie 30 sztuk w kadym zakresie.</td>
              </tr>
            </tbody>
          </table>
        </div>

        <div className="ridgesTable">
          <table>
            <tbody>
              <tr>
                <td colSpan="2">Dodaj nowy kolor</td>
              </tr>
              <tr>
                <td>
                  <input
                    value={this.state.newColor.label}
                    onChange={this.handleNewColorLabelChange}
                    placeholder="Label"
                  />
                </td>
                <td>
                  <input
                    value={this.state.newColor.color}
                    onChange={this.handleNewColorColorChange}
                    placeholder="Kolor"
                  />
                </td>
                <td>
                  <button onClick={() => this.saveNewColor()}>Zapisz</button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div className="ridgesTable">
          <table>
            <thead>
              <tr>
                <td>Label</td>
                <td>Kolor</td>
              </tr>
            </thead>
            <tbody>
              {this.state.colors.map((element, index) => (
                <tr key={index}>
                  <td>{element.label}</td>
                  <td style={{ background: element.color }}>{element.color}</td>
                  <td>
                    <button onClick={() => this.removeColor(element.id)}>
                      Usuń
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        <div className="ridgesTable">
          <table>
            <tbody>
              <tr>
                <td colSpan="2">
                  Dodaj nowy zakres numerów dla partii zamówien
                </td>
              </tr>
              <tr>
                <td>
                  <input
                    value={this.state.newRange.fromNumber}
                    onChange={this.handleNewRangeFromNumberChange}
                    placeholder="Od zamówienia numer"
                  />
                </td>
                <td>
                  <input
                    value={this.state.newRange.toNumber}
                    onChange={this.handleNewRangeToNumberChange}
                    placeholder="Do zamówienia numer"
                  />
                </td>
                <td>
                  <select
                    value={this.state.newRange.colorId}
                    onChange={this.handleNewRangeColorIdChange}
                  >
                    <option value="">Kolor</option>
                    {this.state.colors.map((element, index) => (
                      <option key={index} value={element.id}>
                        {element.label}
                      </option>
                    ))}
                  </select>
                </td>
                <td>
                  <button onClick={() => this.saveNewRange()}>Zapisz</button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div className="ridgesTable">
          <table>
            <thead>
              <tr>
                <td>Od zamówienia numer</td>
                <td>Do zamówienia numer</td>
                <td>Kolor</td>
              </tr>
            </thead>
            <tbody>
              {this.state.ranges.map((element, index) => (
                <tr key={index}>
                  <td>{element.fromNumber}</td>
                  <td>{element.toNumber}</td>
                  <td>{this.getColorById(element.colorId)}</td>
                  <td>
                    <button onClick={() => this.removeRange(element.id)}>
                      Usuń
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    );
  }
}

const initState = {
  colors: [],
  ranges: [],
  newColor: {
    label: "",
    color: "",
  },
  newRange: {
    fromNumber: "",
    toNumber: "",
    colorId: "",
  },
  optionalR1MaxOrderNumber: ""
};

const mapStateToProps = (state) => {
  return {
    authorized: state.authorized,
  };
};

const mapDispatchToProps = { unauthorize };

export default connect(mapStateToProps, mapDispatchToProps)(BatchColors);
