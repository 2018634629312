import React, { Component } from "react";
import { BACKEND_URL } from "../constants";

class NewCompleteButton extends Component {
  complete = () => {
    if (this.props.id) {
      fetch(
        BACKEND_URL +
          "/newPersonalization/archive/" +
          this.props.id,
        {
          credentials: "include",
          method: "POST",
        }
      ).then(() => {
        if (this.props.reloadData) {
          this.props.reloadData();
        }
      });
    }
  };

  render() {
    return (
      <div className="printButton highlightedButton" onClick={this.complete} style={{background: this.props.color}}>
        <div className="printButtonInner">Zakończ</div>
      </div>
    );
  }
}

export default NewCompleteButton;
