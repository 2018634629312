import React, { Component } from "react";
import { unauthorize } from "../actions";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { BACKEND_URL } from "../constants";
import { Chart } from "react-charts";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

class StatisticsComponent extends Component {
  constructor(props) {
    super(props);
    this.state = initState;
  }

  componentDidMount() {
    this.getData();
  }

  componentWillUnmount() {
    this.setState(initState);
  }

  getData = () => {
    const that = this;
    fetch(
      BACKEND_URL +
        "/statistics/productType?startDate=" +
        new Date(this.state.startDate).toISOString().slice(0, 10) +
        "&endDate=" +
        new Date(this.state.endDate).toISOString().slice(0, 10),
      {
        credentials: "include",
        method: "POST",
      }
    )
      .then((res) => {
        if (res.status === 403 || res.status === 401) {
          throw Error("Response status code " + res.status);
        }
        return res;
      })
      .then((res) => res.json())
      .then((res) => {
        that.setState({
          productTypeStatistics: this.transfromData(res),
        });
      })
      .catch((err) => {
        console.error(err);
        that.props.unauthorize();
      });
  };


  getFormatData = () => {
    const that = this;
    fetch(
      BACKEND_URL +
        "/statistics/productType/" + this.productType + "?startDate=" +
        new Date(this.state.startDate).toISOString().slice(0, 10) +
        "&endDate=" +
        new Date(this.state.endDate).toISOString().slice(0, 10),
      {
        credentials: "include",
        method: "POST",
      }
    )
      .then((res) => {
        if (res.status === 403 || res.status === 401) {
          throw Error("Response status code " + res.status);
        }
        return res;
      })
      .then((res) => res.json())
      .then((res) => {
        that.setState({
          formatStatistics: this.transfromData(res),
        });
      })
      .catch((err) => {
        console.error(err);
        that.props.unauthorize();
      });
  };

  transfromData = (data) => {
    let keys = Object.keys(data);
    let result = [];
    keys.map((key) =>
      result.push({
        label: key,
        data: [[0, data[key]]],
      })
    );
    return result;
  };

  setStartDate = (date) => {
    this.setState({ startDate: date }, this.getData);
  };

  setEndDate = (date) => {
    this.setState({ endDate: date }, this.getData);
  };

  mapProductTypeToOption = (element, index) => {
    return (
      <option>{element.label}</option>
    );
  }

  productTypeChange = (event) => {
    if (event && event.target) {
      this.productType = event.target.value;
      this.getFormatData();
    }
  }

  render() {
    console.log(this.state.productTypeStatistics);
    if (!this.props.authorized) {
      return <Redirect to="/login" />;
    }

    const series = {
      type: "bar",
    };

    const axes = [
      { primary: true, type: "ordinal", position: "bottom" },
      { position: "left", type: "linear", stacked: false },
    ];

    return (
      <div>
        <div className="tablePageTopMenu">
          <button onClick={this.logout}>Wyloguj</button>
          <button onClick={() => this.props.history.push("/")}>Powrót</button>
          <div className="grow" />
        </div>

        <DatePicker
          selected={this.state.startDate}
          onChange={(date) => this.setStartDate(date)}
          dateFormat="yyyy-MM-dd"
        />
        <DatePicker
          selected={this.state.endDate}
          onChange={(date) => this.setEndDate(date)}
          dateFormat="yyyy-MM-dd"
        />

        <br />
        <br />

        <div
          style={{
            background: "white",
            width: "100%",
            height: "300px",
          }}
        >
          <Chart
            data={this.state.productTypeStatistics}
            axes={axes}
            series={series}
            tooltip
          />
        </div>

        <br />
        <br />

        <select onChange={this.productTypeChange}>
          <option>Wybierz</option>
          {this.state.productTypeStatistics.map((element, index) => this.mapProductTypeToOption(element, index))}
        </select>

        <div
          style={{
            background: "white",
            width: "100%",
            height: "300px",
          }}
        >
          <Chart
            data={this.state.formatStatistics}
            axes={axes}
            series={series}
            tooltip
          />
        </div>
      </div>
    );
  }
}

const initState = {
  productTypeStatistics: [],
  formatStatistics: [],
  startDate: new Date() - 24 * 60 * 60 * 1000 * 30,
  endDate: new Date(),
  productType: ""
};

const mapStateToProps = (state) => {
  return {
    authorized: state.authorized,
  };
};

const mapDispatchToProps = { unauthorize };

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(StatisticsComponent);
