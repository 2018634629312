import React, {Component} from "react";
import {HashRouter, Route} from "react-router-dom";
import CanvasesPackageTable from "./CanvasesPackageTable";

class CanvasesPackagePage extends Component {

  render() {
    return (<HashRouter>
      <Route exact path="/canvases/package" component={CanvasesPackageTable}/>
    </HashRouter>
    );
  }
}

export default CanvasesPackagePage;