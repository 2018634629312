import React, {Component} from "react";
import {BACKEND_URL} from "../constants";

class PrintButton extends Component {

    print = () => {
      if (this.props.url && this.props.id) {
        fetch(BACKEND_URL + this.props.url + this.props.id, {
          credentials: "include"
        }).then(() => {
          if (this.props.reloadData) {
            this.props.reloadData();
          }
        });
      }
    }

    render() {
      return (
        <div className="printButton highlightedButton" style={{background: this.props.color}} onClick={this.print}>
          <div className="printButtonInner">
                    Drukuj
          </div>
        </div>
      );
    }
}

export default PrintButton;