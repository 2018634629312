import React, {Component} from "react";

class QuantitySelect extends Component {

  render() {
    return (
      <select onChange={this.props.setQuantity} className="limitInput">
        <option value="10">10</option>
        <option value="25">25</option>
        <option value="50">50</option>
        <option value="100">100</option>
        <option value={this.props.counter}>{this.props.counter}</option>
      </select>
    );
  }
}

export default QuantitySelect;