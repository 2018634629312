import React, {Component} from "react";
import {unauthorize} from "../actions";
import {connect} from "react-redux";
import {NavLink, Redirect} from "react-router-dom";
import {BACKEND_URL} from "../constants";

class ChooseViewPage extends Component {

  constructor(props) {
    super(props);
    this.state = initState;
  }

  componentDidMount() {
    let that = this;
    fetch(BACKEND_URL + "/views", {
      credentials: "include"
    })
      .then((res) => {
        if (res.status === 403 || res.status === 401) {
          throw Error("Response status code " + res.status);
        }
        return res;
      })
      .then(res => res.json())
      .then(availableViews => {
        that.setState({
          availableViews: availableViews
        });
      })
      .catch(err => {
        console.error(err);
        that.props.unauthorize();
      });
  }

  componentWillUnmount() {
    this.setState(initState);
  }

  render() {
    if (!this.props.authorized) {
      return (
        <Redirect to="/login"/>
      );
    }
    return (
      <div>
        <div className="viewsContainer">
          {this.state.availableViews.map((element, index) => (
            <NavLink to={element.url} key={index}>
              <div className="viewFlex">{element.name}</div>
            </NavLink>
          ))}
        </div>
      </div>
    );
  }
}

const initState = {
  availableViews: []
};

const mapStateToProps = (state) => {
  return {
    authorized: state.authorized
  };
};

const mapDispatchToProps = {unauthorize};

export default connect(mapStateToProps, mapDispatchToProps)(ChooseViewPage);