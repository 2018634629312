import React, {Component} from "react";
import {BACKEND_URL} from "../constants";


class CanvasesPrintButton extends Component {

    action = (archive) => {
      if (this.props.id) {
        fetch(BACKEND_URL + "/canvases/package/" + (!archive ? "print" : "revert") + "/" + this.props.id, {
          method: "POST",
          credentials: "include"
        }).then(() => {
          if (this.props.reloadData) {
            this.props.reloadData();
          }
        });
      }
    }

    render() {
      const label = (!this.props.archive ?
        <div className="archiveButtonInner">Naklejka</div> : <div className="archiveButtonInner">Przywróć</div>);
      return (
        <div className="archiveButton highlightedButton" onClick={() => this.action(this.props.archive)}>
          {label}
        </div>
      );
    }
}

export default CanvasesPrintButton;
