import React, {Component} from "react";
import {HashRouter, Route} from "react-router-dom";
import RidgesTable from "./RidgesTable";

class RidgesPage extends Component {
  render() {
    return (<HashRouter>
      <Route exact path="/ridges" component={RidgesTable}/>
    </HashRouter>);
  }
}

export default RidgesPage;