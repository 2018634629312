import React, {Component} from "react";
import {HashRouter, Route} from "react-router-dom";
import PersonalizationTable from "./PersonalizationTable";

class PersonalizationPage extends Component {
  render() {
    return (<HashRouter>
      <Route exact path="/personalization" component={PersonalizationTable}/>
    </HashRouter>);
  }
}

export default PersonalizationPage;