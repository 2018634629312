import React, {Component} from "react";
import {HashRouter, Route} from "react-router-dom";
import NewPersonalizationTable from "./NewPersonalizationTable";

class NewPersonalizationPage extends Component {
  render() {
    return (<HashRouter>
      <Route exact path="/newPersonalization" component={NewPersonalizationTable}/>
    </HashRouter>);
  }
}

export default NewPersonalizationPage;