import React, { Component } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { unauthorize } from "../actions";
import NewPaging from "../components/NewPaging";
import { BACKEND_URL } from "../constants";
import QuantitySelect from "../components/QuantitySelect";
import LoadingOverlay from 'react-loading-overlay';

class NewPanelRawOrderDataTable extends Component {

    constructor(props) {
        super(props);
        this.state = initState;
    }

    componentDidMount() {
        this.getData();
        setInterval(
            function () {
                this.getData();
            }.bind(this),
            60 * 1000
        );
    }

    getData = () => {
        this.setState({
            loading: true
        });
        const that = this;
        const size = this.state.size;
        const page = this.state.page;
        fetch(BACKEND_URL + "/new_panel/data?size=" + size + "&page=" + page, {
            credentials: "include"
        })
            .then((res) => {
                if (res.status === 403 || res.status === 401) {
                    throw Error("Response status code " + res.status);
                }
                return res;
            })
            .then(res => res.json())
            .then(res => {
                that.setState({
                    orders: res.orderItems,
                    totalElements: res.totalElements,
                    totalPages: res.totalPages,
                    loading: false
                });
            })
            .catch(err => {
                console.error(err);
                that.props.unauthorize();
            });
    }

    setQuantity = (event) => {
        let value = event.target.value;
        this.setState({
            size: parseInt(value), //FIXME
            page: 0
        }, () => this.getData());
    }

    showPreviousElements = () => {
        const page = this.state.page;
        if (page > 0) {
            this.setState({
                page: page - 1
            }, () => this.getData());
        }
    }

    showNextElements = () => {
        const page = this.state.page;
        const totalPages = this.state.totalPages;
        if (page < totalPages - 1) {
            this.setState({
                page: page + 1
            }, () => this.getData());
        }
    }

    renderMedias = (medias) => {
        console.log(medias);
        if (medias && medias.length > 0) {
            return (
                <div class="mediasList">
                    {medias.map((media, media_index) => (
                        <div key={media_index}>
                            <a href={media.url} target="_blank">{media.fileName}</a><br />
                        </div>
                    ))}
                </div>
            )
        }
    }

    render() {
        if (!this.props.authorized) {
            return (
                <Redirect to="/login" />
            );
        }
        return (
            <div>
                <div className="tablePageTopMenu">
                    <button onClick={this.logout}>Wyloguj</button>
                    <button onClick={() => this.props.history.push("/")}>Powrót</button>
                    <div className="grow" />
                    <NewPaging size={this.state.size} page={this.state.page} totalPages={this.state.totalPages} totalElements={this.state.totalElements} />
                    <button onClick={this.showPreviousElements}>-{this.state.size}</button>
                    <button onClick={this.showNextElements}>+{this.state.size}</button>
                </div>
                <LoadingOverlay
                    active={this.state.loading}
                    spinner
                    text='Trwa ładowanie danych'
                >
                    <div className="coversTable">
                        <table>
                            <thead>
                                <tr>
                                    <td>Nr</td>
                                    <td>Pozycja</td>
                                    <td>Nazwa</td>
                                    <td>Opis</td>
                                    <td>Ilość</td>
                                    <td>Atrybuty</td>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.orders.map((element, index) => (
                                    <tr key={index}>
                                        <td>{element.orderNumber}</td>
                                        <td>{element.position}</td>
                                        <td>{element.label}</td>
                                        <td>{element.description}</td>
                                        <td>{element.quantity}</td>
                                        <td>
                                            {element.options.map((option, option_index) => (
                                                <div key={option_index}>
                                                    {option.parameterName} = {option.parameterValue}
                                                    {this.renderMedias(option.medias)}
                                                </div>
                                            ))}
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </LoadingOverlay>
                <div className="tablePageBottomMenu">
                    <div className="grow" />
                    <QuantitySelect counter={this.props.totalElements} setQuantity={this.setQuantity} />
                </div>
            </div>
        )
    }
}

const initState = {
    orders: [],
    size: 10,
    page: 0,
    totalElements: undefined,
    loading: true
};

const mapStateToProps = (state) => {
    return {
        authorized: state.authorized
    };
};

const mapDispatchToProps = { unauthorize };

export default connect(mapStateToProps, mapDispatchToProps)(NewPanelRawOrderDataTable);
