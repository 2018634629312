import React, {Component} from "react";
import {HashRouter, Route} from "react-router-dom";
import CrystalBoardsTable from "./CrystalBoardsTable";

class CrystalBoardsPackagePage extends Component {

  render() {
    return (<HashRouter>
      <Route exact path="/crystalboards/package" component={CrystalBoardsTable}/>
    </HashRouter>
    );
  }
}

export default CrystalBoardsPackagePage;