import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import { unauthorize } from "../actions";
import { connect } from "react-redux";
import { BACKEND_URL } from "../constants";
import CompleteButton from "../components/CompleteButton";
import PrevButton from "../components/PrevButton";
import NextButton from "../components/NextButton";
import Paging from "../components/Paging";
import QuantitySelect from "../components/QuantitySelect";
import EditCommentModal from "../components/Modal/EditCommentModal";
import editIcon from "../images/edit_icon.png";

class StampingsTable extends Component {
  constructor(props) {
    super(props);
    this.state = initState;
  }

  componentDidMount() {
    this.getData();
    setInterval(
      function () {
        this.getData();
      }.bind(this),
      60 * 1000
    );
  }

  getData = () => {
    const that = this;
    const quantity = this.state.quantity;
    const offset = this.state.offset;
    const searchPhrase = this.state.searchPhrase;
    const showArchived = this.state.showArchived;
    fetch(
      BACKEND_URL +
      "/stampings?quantity=" +
      quantity +
      "&offset=" +
      offset +
      (searchPhrase ? "&orderNumberPattern=" + searchPhrase : "") +
      (showArchived ? "&showArchived=true" : ""),
      {
        credentials: "include",
      }
    )
      .then((res) => {
        if (res.status === 403 || res.status === 401) {
          throw Error("Response status code " + res.status);
        }
        return res;
      })
      .then((res) => res.json())
      .then((res) => {
        that.setState({
          stampings: res.data,
          counter: res.counter,
        });
      })
      .catch((err) => {
        console.error(err);
        that.props.unauthorize();
      });
  };

  getOrderUrl = (element) => {
    return (
      "https://panel.crystal-albums.pl/laborders/orders/edit/order_id/" +
      element.orderNumber
    );
  };

  getAlbumOrderUrl = (element) => {
    return (
      "https://panel.crystal-albums.pl/laborders/albumorders/details/order_id/" +
      element.orderNumber +
      "/album_order_config_id/" +
      element.albumOrderNumber
    );
  };

  setQuantity = (event) => {
    let value = event.target.value;
    this.setState(
      {
        quantity: parseInt(value), //FIXME
        offset: 0,
      },
      () => this.getData()
    );
  };

  logout = () => {
    let that = this;
    fetch(BACKEND_URL + "/logout", { credentials: "include" })
      .then(() => that.props.unauthorize())
      .catch((err) => console.log("Logout error", err));
  };

  switchShowArchived = () => {
    this.setState(
      {
        showArchived: !this.state.showArchived,
        offset: 0,
      },
      () => this.getData()
    );
  };

  search = (event) => {
    const phrase = event.target.value;
    this.setState(
      {
        searchPhrase: phrase,
        offset: 0,
      },
      () => this.getData()
    );
  };

  markIfEmpty = (value) => {
    return value ? value : <div className="redBox" />;
  };

  getOrNoData = (element, label) => {
    return (
      <div className={element ? "" : "personalizationPropertyNotFound"}>
        {label}:&nbsp;{element ? element : "nie podano"}
      </div>
    );
  };

  getMaterialOrNoData = (element, label) => {
    return (
      <div className={(element.materialSeries || element.materialName) ? "" : "personalizationPropertyNotFound"}>
        {label}:&nbsp;{(element.materialSeries || element.materialName) ? (element.materialSeries + " / " + element.materialName) : "nie podano"}
      </div>
    );
  };

  showPersonalizationProperties = (index) => {
    const el = document.getElementById("personalizationProperties" + index);
    el.classList.toggle("show");
    const elButton = document.getElementById("showHideButton" + index);
    elButton.innerText = elButton.classList.contains("show")
      ? "Rozwiń"
      : "Zwiń";
  };

  showAll = () => {
    const els = document.querySelectorAll('[id^="personalizationProperties"]');
    els.forEach((el) => {
      if (!el.classList.contains("show")) {
        el.classList.add("show");
      }
    });

    const elsButtons = document.querySelectorAll('[id^="showHideButton"]');
    elsButtons.forEach((el) => {
      el.innerText = "Zwiń";
    });
  };

  hideAll = () => {
    const els = document.querySelectorAll('[id^="personalizationProperties"]');
    els.forEach((el) => {
      if (el.classList.contains("show")) {
        el.classList.remove("show");
      }
    });

    const elsButtons = document.querySelectorAll('[id^="showHideButton"]');
    elsButtons.forEach((el) => {
      el.innerText = "Rozwiń";
    });
  };

  mapMediasToButtons = (media, index) => {
    return (
      <div key={index}>
        <a href={BACKEND_URL + "/personalization/file/" + media.id}>
          Pobierz załącznik - {media.parameterName}
        </a>
      </div>
    );
  };

  showModal = (id, comment) => {
    this.setState({
      showModal: true,
      commentEditId: id,
      commentEditText: comment
    });
  }

  handleCloseModal = (saveComment) => {
    this.setState({
      showModal: false
    });

    if (saveComment) {
      this.saveComment();
    }
  }

  handleChangeComment = (comment) => {
    this.setState({
      commentEditText: comment
    });
  }

  saveComment = () => {
    let that = this;
    const requestOption = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ comment: this.state.commentEditText }),
      credentials: "include"
    };

    fetch(BACKEND_URL + `/personalization/${this.state.commentEditId}/comment`, requestOption)
      .then((res) => {
        if (res.status === 403 || res.status === 401) {
          throw Error("Response status code " + res.status);
        }
      })
      .then(() => {
        this.setState({
          commentEditId: initState.commentEditId,
          commentEditText: initState.commentEditText,
        }, () => this.getData());
      })
      .catch(err => {
        console.error(err);
        that.props.unauthorize();
      });
  }

  mapElementToRow = (element, index) => {
    const completeButton = this.state.showArchived ? (
      ""
    ) : (
      <CompleteButton id={element.id} reloadData={this.getData} />
    );
    let backSection;
    if (element.logotype != null && element.logotype) {
      backSection = (
        <div>
          <br />
          <div>Tył:</div>
          <div>
            <div
              className={
                element.logotype ? "" : "personalizationPropertyNotFound"
              }>
              Logotyp z tyłu okładki:&nbsp;{element.logotype ? "tak" : "nie"}
            </div>
            {this.getOrNoData(
              element.logotypePosition,
              "Pozycja logotypu na okładce"
            )}
            <div
              className={
                element.logotypeOverlay ? "" : "personalizationPropertyNotFound"
              }>
              Biały poddruk pod nadrukiem UV:&nbsp;
              {element.logotypeOverlay != null
                ? element.logotypeOverlay
                  ? "tak"
                  : "nie"
                : "nie podano"}
            </div>
          </div>
        </div>
      );
    } else {
      backSection = "";
    }

    let mediaSection;
    if (
      element.medias != null &&
      element.medias &&
      Array.isArray(element.medias) &&
      element.medias.length
    ) {
      mediaSection = (
        <div>
          <br />
          <div>Załączniki:</div>
          <div>
            {element.medias.map((media, index) =>
              this.mapMediasToButtons(media, index)
            )}
          </div>
        </div>
      );
    } else {
      mediaSection = "";
    }

    let templateOrMatrixNumber;
    if (element.preparedTemplate != null) {
      templateOrMatrixNumber = element.preparedTemplate;
    } else if (element.matrixNumber != null) {
      templateOrMatrixNumber = element.matrixNumber;
    }

    return (
      <tr
        key={index}
        className={element.status === "Poprawka" ? "inCorrection" : null}>
        <td
          width="5%"
          style={{ background: element.batchColor }}
          className={element.archived ? "archivedRow" : null}>
          {" "}
          {/*FIXME width*/}
          <a
            href={this.getOrderUrl(element)}
            target="_blank"
            rel="noopener noreferrer"
            className="tableLink">
            {element.orderNumber}
          </a>
        </td>
        <td width="5%" className={element.archived ? "archivedRow" : null}>
          {" "}
          {/*FIXME width*/}
          <a
            href={this.getAlbumOrderUrl(element)}
            target="_blank"
            rel="noopener noreferrer"
            className="tableLink">
            {element.albumOrderNumber}
          </a>
        </td>
        <td width="30%">
          <div>{element.suborderName}</div>
          <div className="personalizationComment">{element.comment}</div>
          <div className="comment">
            <button className="btnLink" onClick={() => this.showModal(element.id, element.caComment)}>
              <img src={editIcon} className="editIcon" alt="Edytuj komentarz" />
            </button>
            {element.caComment}
          </div>
          <div
            className="stampingsPersonalizationProperties"
            id={"personalizationProperties" + index}>
            <div>Przód:</div>
            <div className="frontPersonalization">
              <div className="frontPersonalizationLeft">
                {this.getOrNoData(element.productType, "Typ produktu")}
                {this.getOrNoData(element.format, "Format")}
                {this.getMaterialOrNoData(element, "Materiał")}
                {this.getOrNoData(
                  element.personalizationMethod,
                  "Metoda personalizacji"
                )}
                {this.getOrNoData(
                  element.personalizationPosition,
                  "Pozycja personalizacji"
                )}
              </div>
            </div>
            {backSection}
            {mediaSection}
          </div>
        </td>
        <td width="10%">{element.productType}</td>
        <td width="5%">{this.markIfEmpty(element.format)}</td>
        <td width="5%">{this.markIfEmpty(element.stampingColor)}</td>
        <td width="10%">{templateOrMatrixNumber}</td>
        <td width="20%"><b>1:</b> {element.personalizationFirstLine}<br />2: {element.personalizationSecondLine}<br />
          {element.logotype ? "LOGOTYP!" : ""}
        </td>
        <td width="5%">{element.quantity}</td>
        <td width="12%">
          <div className="personalizationTableButtons">
            <div
              className="printButton"
              onClick={() => this.showPersonalizationProperties(index)}>
              <div className="printButtonInner" id={"showHideButton" + index}>
                Rozwiń
              </div>
            </div>
            {completeButton}
          </div>
        </td>
      </tr>
    );
  };

  setOffsetAndRefresh = (newOffset) => {
    this.setState(
      {
        offset: newOffset,
      },
      () => this.getData()
    );
  };

  render() {
    if (!this.props.authorized) {
      return <Redirect to="/login" />;
    }
    const quantity = this.state.quantity;
    const showArchivedButton = (
      <button onClick={this.switchShowArchived}>
        {this.state.showArchived ? "Produkcja" : "Archiwum"}
      </button>
    );
    return (
      <div>
        <div className="tablePageTopMenu">
          <button onClick={this.logout}>Wyloguj</button>
          <button onClick={() => this.props.history.push("/")}>Powrót</button>
          {showArchivedButton}
          <input
            type="text"
            value={this.state.searchPhrase}
            onChange={this.search}
            placeholder="Wyszukaj"
            className="searchInput"
          />
          <div className="grow" />
          <button onClick={this.hideAll}>Zwiń wszystkie</button>
          <button onClick={this.showAll}>Rozwiń wszystkie</button>
          <Paging
            quantity={quantity}
            offset={this.state.offset}
            counter={this.state.counter}
          />
          <PrevButton
            quantity={quantity}
            offset={this.state.offset}
            callback={this.setOffsetAndRefresh}
          />
          <NextButton
            quantity={quantity}
            offset={this.state.offset}
            counter={this.state.counter}
            callback={this.setOffsetAndRefresh}
          />
        </div>
        <div className="coversTable">
          <table>
            <thead>
              <tr>
                <td>Nr zlecenia</td>
                <td>Nr podzlecenia</td>
                <td>Nazwa</td>
                <td>Typ</td>
                <td>Format</td>
                <td>Kolor</td>
                <td>Wzór/matryca</td>
                <td>Tekst</td>
                <td>Ilość</td>
                <td>Opcje</td>
              </tr>
            </thead>
            <tbody>
              {this.state.stampings.map((element, index) =>
                this.mapElementToRow(element, index)
              )}
            </tbody>
          </table>
        </div>
        <div className="tablePageBottomMenu">
          <div className="grow" />
          <QuantitySelect
            counter={this.state.counter}
            setQuantity={this.setQuantity}
          />
        </div>
        <EditCommentModal
          isModalOpen={this.state.showModal}
          close={this.handleCloseModal}
          onChangeComment={this.handleChangeComment}
          comment={this.state.commentEditText} />
      </div>
    );
  }
}

const initState = {
  stampings: [],
  quantity: 10,
  offset: 0,
  searchPhrase: "",
  counter: 0,
  showArchived: false,
  showModal: false,
  commentEditId: 0,
  commentEditText: "",
};

const mapStateToProps = (state) => {
  return {
    authorized: state.authorized,
  };
};

const mapDispatchToProps = { unauthorize };

export default connect(mapStateToProps, mapDispatchToProps)(StampingsTable);
