import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import { unauthorize } from "../actions";
import { connect } from "react-redux";
import { BACKEND_URL } from "../constants";
import PrintButton from "../components/PrintButton";
import CorrectButton from "../components/CorrectButton";
import PrevButton from "../components/PrevButton";
import NextButton from "../components/NextButton";
import Paging from "../components/Paging";
import QuantitySelect from "../components/QuantitySelect";
import EditCommentModal from "../components/Modal/EditCommentModal";
import editIcon from "../images/edit_icon.png";

class BlocksTable extends Component {

  constructor(props) {
    super(props);
    this.state = initState;
  }

  componentDidMount() {
    if (this.props.match?.params?.orderNumber) {
      this.setState({
        searchPhrase: this.props.match?.params?.orderNumber
      }, this.getData);
    } else {
      this.getData();
    }
    setInterval(
      function () {
        this.getData();
      }.bind(this),
      60 * 1000
    );
  }

  getData = () => {
    const that = this;
    const quantity = this.state.quantity;
    const offset = this.state.offset;
    const searchPhrase = this.state.searchPhrase;
    const showArchived = this.state.showArchived;
    fetch(BACKEND_URL + "/blocks?quantity=" + quantity +
      "&offset=" + offset +
      (searchPhrase ? "&orderNumberPattern=" + searchPhrase : "") +
      (showArchived ? "&showArchived=true" : ""), {
      credentials: "include"
    })
      .then((res) => {
        if (res.status === 403 || res.status === 401) {
          throw Error("Response status code " + res.status);
        }
        return res;
      })
      .then(res => res.json())
      .then(res => {
        that.setState({
          blocks: res.data,
          counter: res.counter
        });
      })
      .catch(err => {
        console.error(err);
        that.props.unauthorize();
      });
  }

  setQuantity = (event) => {
    let value = event.target.value;
    this.setState({
      quantity: parseInt(value), //FIXME
      offset: 0
    }, () => this.getData());
  }

  logout = () => {
    let that = this;
    fetch(BACKEND_URL + "/logout", { credentials: "include" })
      .then(() => that.props.unauthorize())
      .catch((err) => console.log("Logout error", err));
  }

  switchShowArchived = () => {
    this.setState({
      showArchived: !this.state.showArchived,
      offset: 0
    }, () => this.getData());
  }

  showModal = (id, comment) => {
    this.setState({
      showModal: true,
      commentEditId: id,
      commentEditText: comment
    });
  }

  handleCloseModal = (saveComment) => {
    this.setState({
      showModal: false
    });

    if (saveComment) {
      this.saveComment();
    }
  }

  handleChangeComment = (comment) => {
    this.setState({
      commentEditText: comment
    });
  }

  saveComment = () => {
    let that = this;
    const requestOption = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ comment: this.state.commentEditText }),
      credentials: "include"
    };

    fetch(BACKEND_URL + `/block/${this.state.commentEditId}/comment`, requestOption)
      .then((res) => {
        if (res.status === 403 || res.status === 401) {
          throw Error("Response status code " + res.status);
        }
      })
      .then(() => {
        this.setState({
          commentEditId: initState.commentEditId,
          commentEditText: initState.commentEditText,
        }, () => this.getData());
      })
      .catch(err => {
        console.error(err);
        that.props.unauthorize();
      });
  }

  search = (event) => {
    const phrase = event.target.value;
    this.setState({
      searchPhrase: phrase,
      offset: 0
    }, () => this.getData());
  }

  getOrderUrl = element => {
    return "https://panel.crystal-albums.pl/laborders/orders/edit/order_id/" + element.orderNumber;
  }

  getAlbumOrderUrl = (element) => {
    return "https://panel.crystal-albums.pl/laborders/albumorders/details/order_id/" +
      element.orderNumber + "/album_order_config_id/" + element.albumOrderNumber;
  }

  markIfEmpty = value => {
    return value ? value : <div className="redBox" />;
  }

  setOffsetAndRefresh = (newOffset) => {
    this.setState({
      offset: newOffset,
    }, () => this.getData());
  }

  topMenu = () => {
    const quantity = this.state.quantity;
    const showArchivedButton = <button
      onClick={this.switchShowArchived}>{this.state.showArchived ? "Produkcja" : "Archiwum"}</button>;
    return <div className="tablePageTopMenu">
      <button onClick={this.logout}>Wyloguj</button>
      <button onClick={() => this.props.history.push("/")}>Powrót</button>
      <button onClick={() => this.props.history.push("/blocks/numpad")}>Nowe wyszukiwanie</button>
      {showArchivedButton}
      <input type="text" value={this.state.searchPhrase} onChange={this.search} placeholder="Wyszukaj"
        className="searchInput" />
      <div className="grow" />
      <Paging quantity={quantity} offset={this.state.offset} counter={this.state.counter} />
      <PrevButton quantity={quantity} offset={this.state.offset} callback={this.setOffsetAndRefresh} />
      <NextButton quantity={quantity} offset={this.state.offset} counter={this.state.counter}
        callback={this.setOffsetAndRefresh} />
    </div>;
  }

  orderId = (orderId) => {
    return <div className="orderId">
      SP {orderId}
    </div>;
  }

  render() {
    if (!this.props.authorized) {
      return (
        <Redirect to="/login" />
      );
    }

    return (
      <div>
        {this.topMenu()}
        <div className="coversTable">
          <table>
            <thead>
              <tr>
                <td>Nr</td>
                <td>Nazwa</td>
                <td>Typ</td>
                <td>Format</td>
                <td>Ilość</td>
                <td>Opcje</td>
                <td>Status</td>
              </tr>
            </thead>
            <tbody>
              {this.state.blocks.map((element, index) => (
                <tr key={index} className={element.status === "Poprawka" ? "inCorrection" : null}>
                  <td width="5%" style={{ background: element.batchColor }} className={element.archived ? "archivedRow" : null}> {/*FIXME width*/}
                    <a href={this.getOrderUrl(element)} target="_blank" rel="noopener noreferrer"
                      className="tableLink">
                      {element.orderNumber}
                    </a>{element.orderId != null ? this.orderId(element.orderId) : ""}
                  </td>
                  <td width="50%">
                    <div>
                      <a href={this.getAlbumOrderUrl(element)} target="_blank"
                        rel="noopener noreferrer" className="tableLink">
                        {element.suborderName}
                      </a>
                    </div>
                    <div className="coversComment">{element.comment}</div>
                    <div className="comment">
                      <button className="btnLink" onClick={() => this.showModal(element.id, element.caComment)}>
                        <img src={editIcon} className="editIcon" alt="Edytuj komentarz"/>
                      </button>
                      {element.caComment}
                    </div>
                  </td>
                  <td width="10%">{this.markIfEmpty(element.productType)}</td>
                  <td width="10%">{this.markIfEmpty(element.format)}</td>
                  <td width="4%">{element.quantity} <sup>({element.originalQuantity})</sup></td>
                  <td width="11%">
                    <div className="coversTableButtons">
                      <CorrectButton id={element.id} reloadData={this.getData}
                        inCorrection={element.status === "Poprawka"} />
                      <div className="grow" />
                      <PrintButton url={"/print/block/"} id={element.id} reloadData={this.getData} />
                    </div>
                  </td>
                  <td width="20%">
                    {element.status}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div className="tablePageBottomMenu">
          <div className="grow" />
          <QuantitySelect counter={this.state.counter} setQuantity={this.setQuantity} />
        </div>
        <EditCommentModal
          isModalOpen={this.state.showModal}
          close={this.handleCloseModal}
          onChangeComment={this.handleChangeComment}
          comment={this.state.commentEditText} />
      </div>
    );
  }
}

const initState = {
  blocks: [],
  quantity: 10,
  offset: 0,
  searchPhrase: "",
  counter: 0,
  showArchived: false,
  showModal: false,
  commentEditId: 0,
  commentEditText: "",
};

const mapStateToProps = (state) => {
  return {
    authorized: state.authorized
  };
};

const mapDispatchToProps = { unauthorize };

export default connect(mapStateToProps, mapDispatchToProps)(BlocksTable);
