import React, {Component} from "react";
import {storeCoversTablePage, unauthorize} from "../actions";
import {connect} from "react-redux";
import {Redirect} from "react-router-dom";
import {BACKEND_URL} from "../constants";
import PrintButton from "../components/PrintButton";
import EditButton from "../components/EditButton";
import CoverModel from "../components/CoverModel";
import Ridge from "../components/Ridge";
import PrevButton from "../components/PrevButton";
import NextButton from "../components/NextButton";
import Paging from "../components/Paging";
import QuantitySelect from "../components/QuantitySelect";
import BatchColorButton from "../components/BatchColorButton";
import EditCommentModal from "../components/Modal/EditCommentModal";
import editIcon from "../images/edit_icon.png";

class CoversTable extends Component {

  constructor(props) {
    super(props);
    this.state = initState;
  }

  componentDidMount() {
    this.getData();
    setInterval(
      function () {
        this.getData();
      }.bind(this),
      60 * 1000
    );
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.offset !== this.props.offset
            || prevProps.counter !== this.props.counter
            || prevProps.quantity !== this.props.quantity) {
      this.getData();
    }
  }

    getOrderUrl = element => {
      return "https://panel.crystal-albums.pl/laborders/orders/edit/order_id/" + element.orderNumber;
    }

    getAlbumOrderUrl = (element) => {
      return "https://panel.crystal-albums.pl/laborders/albumorders/details/order_id/" +
            element.orderNumber + "/album_order_config_id/" + element.albumOrderNumber;
    }

    markIfEmpty = value => {
      return value ? value : <div className="redBox"/>;
    }

    markIfEmptyExcluding = (value, productType, excluded) => {
      if (excluded.includes(productType)) {
        return "";
      }
      return value ? value : <div className="redBox"/>;
    }

    addAdditionalCardInfo = (productType, numberOfCards, additionalCard) => {
      const excluded = ["Pudełko na pendrive", "CrystalBox", "Opakowanie Album", "Opakowanie Odbitki"];
      if (excluded.includes(productType)) {
        return "";
      }
      if (!numberOfCards) {
        return <div className="redBox"/>;
      }
      return numberOfCards + (additionalCard ? " + R" : "");
    }

    getData = () => {
      const that = this;
      const quantity = this.props.quantity;
      const offset = this.props.offset;
      const searchPhrase = this.state.searchPhrase;
      const showArchived = this.state.showArchived;
      fetch(BACKEND_URL + "/covers?quantity=" + quantity +
            "&offset=" + offset +
            (searchPhrase ? "&orderNumberPattern=" + searchPhrase : "") +
            (showArchived ? "&showArchived=true" : ""), {
        credentials: "include"
      })
        .then((res) => {
          if (res.status === 403 || res.status === 401) {
            throw Error("Response status code " + res.status);
          }
          return res;
        })
        .then(res => res.json())
        .then(res => {
          that.setState({
            covers: res.data,
          }, () => {
            that.props.storeCoversTablePage({counter: res.counter});
          });
        })
        .catch(err => {
          console.error(err);
          that.props.unauthorize();
        });
    }

    setQuantity = (event) => {
      this.props.storeCoversTablePage({
        quantity: parseInt(event.target.value)
      });
    }

    search = (event) => {
      const phrase = event.target.value;
      this.setOffsetAndRefresh(0);
      this.setState({
        searchPhrase: phrase,
        offset: 0
      }, () => this.getData());
    }

    logout = () => {
      let that = this;
      fetch(BACKEND_URL + "/logout", {credentials: "include"})
        .then(() => that.props.unauthorize())
        .catch((err) => console.log("Logout error", err));
    }

    switchShowArchived = () => {
      this.setState({showArchived: !this.state.showArchived}, () => {
        if (this.props.offset === 0) {
          this.getData();
        } else {
          this.setOffsetAndRefresh(0);
        }
      });
    }

    setOffsetAndRefresh = (newOffset) => {
      this.props.storeCoversTablePage({
        offset: newOffset
      });
    }

    refreshRidge = (id) => {
      const that = this;
      fetch(BACKEND_URL + "/ridges/refresh/" + id, {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        credentials: "include"
      })
        .then((res) => {
          if (res.status === 403 || res.status === 401) {
            throw Error("Response status code " + res.status);
          }
        })
        .then(() => {
          that.getData();
        })
        .catch(err => {
          console.error(err);
          that.props.unauthorize();
        });
    }

    showModal = (id, comment) => {
      this.setState({
        showModal: true,
        commentEditId: id,
        commentEditText: comment
      });
    }

    handleCloseModal = (saveComment) => {
      this.setState({
        showModal: false
      });
  
      if (saveComment) {
        this.saveComment();
      }
    }
  
    handleChangeComment = (comment) => {
      this.setState({
        commentEditText: comment
      });
    }
  
    saveComment = () => {
      let that = this;
      const requestOption = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ comment: this.state.commentEditText }),
        credentials: "include"
      };
  
      fetch(BACKEND_URL + `/cover/${this.state.commentEditId}/comment`, requestOption)
        .then((res) => {
          if (res.status === 403 || res.status === 401) {
            throw Error("Response status code " + res.status);
          }
        })
        .then(() => {
          this.setState({
            commentEditId: initState.commentEditId,
            commentEditText: initState.commentEditText,
          }, () => this.getData());
        })
        .catch(err => {
          console.error(err);
          that.props.unauthorize();
        });
    }

    addCrystalBoxFormat = (element) => {
      if (element.productType === 'CrystalBox') {
          return (
          <div>{element.crystalBoxFormat}</div>
          )
      }
    }

    render() {
      if (!this.props.authorized) {
        return (
          <Redirect to="/login"/>
        );
      }
      const quantity = this.props.quantity;
      const showArchivedButton = <button
        onClick={this.switchShowArchived}>{this.state.showArchived ? "Produkcja" : "Archiwum"}</button>;

      const coverTypeExclude = ["CrystalBox", "Opakowanie Album", "Opakowanie Odbitki"];
      const coverModelExclude = ["Pudełko na pendrive", "CrystalBox", "Opakowanie Album", "Opakowanie Odbitki"];

      return (
        <div>
          <div className="tablePageTopMenu">
            <button onClick={this.logout}>Wyloguj</button>
            <button onClick={() => this.props.history.push("/")}>Powrót</button>
            {/*<button onClick={this.logout}>Import</button>*/}
            {showArchivedButton}
            <input type="text" onChange={this.search} placeholder="Wyszukaj" className="searchInput"/>
            <BatchColorButton/>
            <div className="grow"/>
            <Paging quantity={quantity} offset={this.props.offset} counter={this.props.counter}/>
            <PrevButton quantity={quantity} offset={this.props.offset} callback={this.setOffsetAndRefresh}/>
            <NextButton quantity={quantity} offset={this.props.offset} counter={this.props.counter}
              callback={this.setOffsetAndRefresh}/>
          </div>

          <div className="coversTable">
            <table>
              <thead>
                <tr>
                  <td>Nr</td>
                  <td>Nazwa</td>
                  <td>Typ</td>
                  <td>Format</td>
                  <td>Ilość kart</td>
                  <td>Materiał</td>
                  <td>Okładka</td>
                  <td>Okienko</td>
                  <td>Grzbiet</td>
                  <td>Ilość</td>
                  <td>Opcje</td>
                </tr>
              </thead>
              <tbody>
                {this.state.covers.map((element, index) => (
                  <tr key={index}>
                    <td width="5%" style={{background: element.batchColor}} className={element.archived ? "archivedRow" : null}> {/*FIXME width*/}
                      <a href={this.getOrderUrl(element)} target="_blank" rel="noopener noreferrer"
                        className="tableLink">
                        {element.orderNumber}
                      </a>
                    </td>
                    <td width="30%">
                      <div>
                        <a href={this.getAlbumOrderUrl(element)} target="_blank"
                          rel="noopener noreferrer" className="tableLink">
                          {element.suborderName}
                        </a>
                      </div>
                      <div className="coversComment">{element.comment}</div>
                      <div className="comment">
                        <button className="btnLink" onClick={() => this.showModal(element.id, element.caComment)}>
                          <img src={editIcon} className="editIcon" alt="Edytuj komentarz"/>
                        </button>
                        {element.caComment}
                      </div>
                    </td>
                    <td width="10%">{this.markIfEmpty(element.productType)}</td>
                    <td width="10%">
                      {this.markIfEmpty(element.format)}
                      {this.addCrystalBoxFormat(element)}
                    </td>
                    <td width="7%">{this.addAdditionalCardInfo(element.productType, element.numberOfCards, element.additionalCard)}</td>
                    <td width="5%">{this.markIfEmpty(element.materialName)}</td>
                    <td width="8%">{this.markIfEmptyExcluding(element.coverType, element.productType, coverTypeExclude)}</td>
                    <td width="5%">
                      <CoverModel value={element.coverModel} productType={element.productType} excluded={coverModelExclude}/>
                    </td>
                    <td width="5%">
                      <Ridge value={element.ridge}/>
                      <button className="refreshRidge" onClick={() => this.refreshRidge(element.id)}>Odśwież</button>
                    </td>
                    <td width="3%">{element.quantity}</td>
                    <td width="11%">
                      <div className="coversTableButtons">
                        <EditButton id={element.id}/>
                        <div className="grow"/>
                        <PrintButton url={"/print/"} id={element.id} reloadData={this.getData} color={element.batchColor}/>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className="tablePageBottomMenu">
            <div className="grow"/>
            <QuantitySelect counter={this.props.counter} setQuantity={this.setQuantity}/>
          </div>
          <EditCommentModal
          isModalOpen={this.state.showModal}
          close={this.handleCloseModal}
          onChangeComment={this.handleChangeComment}
          comment={this.state.commentEditText} />
        </div>
      );
    }
}

const initState = {
  covers: [],
  searchPhrase: "",
  showArchived: false,
  showModal: false,
  commentEditId: 0,
  commentEditText: "",
};

const mapStateToProps = (state) => {
  return {
    authorized: state.authorized,
    ...state.tablePage.coversTablePage
  };
};

const mapDispatchToProps = {unauthorize, storeCoversTablePage};

export default connect(mapStateToProps, mapDispatchToProps)(CoversTable);
