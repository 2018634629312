import React, { Component } from "react";
import "./EditCommentModal.css";
import close from "../../images/icons/close.svg";

class EditCommentModal extends Component {

  closeModal = (saveComment) => {
    this.props.close(saveComment);
  }

  onChange = (event) => {
    this.props.onChangeComment(event.target.value);
  }

  render() {
    return (
      <div>
        {this.props.isModalOpen &&
          <div className="modalBackground">
            <div className="modalContainer">
              <div className="modalCloseIcon" onClick={() => this.closeModal()}>
                <img src={close} alt="close" />
              </div>
              <div className="modalBody">
                <div className="modalTitle">
                  <h2>Edycja komentarza</h2>
                </div>
                <textarea
                  id="comment"
                  className="modalTextArea"
                  onChange={this.onChange}
                  value={this.props.comment}>
                </textarea>
              </div>
              <div className="modalFooter">
                <div className="btnGroup">
                  <button className="btn btnSecondary" onClick={() => this.closeModal()}>Anuluj</button>
                  <button className="btn btnPrimary" onClick={() => this.closeModal(true)}>Zapisz</button>
                </div>
              </div>
            </div>
          </div>
        }
      </div>
    );
  }

}

export default EditCommentModal;