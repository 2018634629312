import React, {Component} from "react";
import {HashRouter, Route} from "react-router-dom";
import StampingsTable from "./StampingsTable";

class StampingsPage extends Component {
  render() {
    return (<HashRouter>
      <Route exact path="/stampings" component={StampingsTable}/>
    </HashRouter>);
  }
}

export default StampingsPage;