import React, {Component} from "react";

class NextButton extends Component {

    showNextElements = () => {
      const offset = this.props.offset;
      const quantity = this.props.quantity;
      const counter = this.props.counter;
      if (offset + quantity < counter) {
        this.props.callback(offset + quantity);
      }
    }

    render() {
      return (
        <button onClick={this.showNextElements}>+{this.props.quantity}</button>
      );
    }
}

export default NextButton;