import React, {Component} from "react";
import {HashRouter, Route} from "react-router-dom";
import StatisticsComponent from "./StatisticsComponent";

class StatisticsPage extends Component {

  render() {
    return (<HashRouter>
      <Route exact path="/statistics" component={StatisticsComponent}/>
    </HashRouter>
    );
  }
}


export default StatisticsPage;