import React, {Component} from "react";
import {HashRouter, Route} from "react-router-dom";
import LoginPage from "./pages/LoginPage";
import ChooseViewPage from "./pages/ChooseViewPage";
import CoversPage from "./pages/CoversPage";
import {connect} from "react-redux";
import "./App.css";
import BlocksPage from "./pages/BlocksPage";
import PersonalizationPage from "./pages/PersonalizationPage";
import NewPersonalizationPage from "./pages/NewPersonalizationPage";
import RidgesPage from "./pages/RidgesPage";
import CrystalBoardsPackagePage from "./pages/CrystalBoardsPackagePage";
import CanvasesPackagePage from "./pages/CanvasesPackagePage";
import StatisticsPage from "./pages/StatisticsPage";
import StampingsPage from "./pages/StampingsPage";
import NewPanelRawOrderDataPage from "./pages/NewPanelRawOrderDataPage";

class App extends Component {

  render() {
    return (
      <HashRouter>
        <div className="content">
          <Route exact path="/" component={ChooseViewPage}/>
          <Route path="/login" component={LoginPage}/>
          <Route path="/covers" component={CoversPage}/>
          <Route path="/blocks" component={BlocksPage}/>
          <Route path="/personalization" component={PersonalizationPage}/>
          <Route path="/newPersonalization" component={NewPersonalizationPage}/>
          <Route path="/ridges" component={RidgesPage}/>
          <Route path="/crystalboards/package" component={CrystalBoardsPackagePage}/>
          <Route path="/canvases/package" component={CanvasesPackagePage}/>
          <Route path="/statistics" component={StatisticsPage}/>
          <Route path="/stampings" component={StampingsPage}/>
          <Route path="/newPanelRawOrderData" component={NewPanelRawOrderDataPage}/>
        </div>
        <div className="footer">
                    © 2021 Crystal Albums - Stickers of Avalone
        </div>
      </HashRouter>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    authorized: state.authorized
  };
};

export default connect(mapStateToProps, null)(App);