import React, {Component} from "react";
import {BACKEND_URL} from "../constants";

class CorrectButton extends Component {

    correct = () => {
      if (this.props.id) {
        fetch(BACKEND_URL + "/correct/block/" + this.props.id, {
          credentials: "include"
        }).then(() => {
          if (this.props.reloadData) {
            this.props.reloadData();
          }
        });
      }
    }

    render() {
      const label = this.props.inCorrection ? <div className="correctionEndLabel">Koniec<br/>poprawki</div> : "Popraw";
      return (
        <div className="correctButton" onClick={this.correct}>
          <div className="correctButtonInner">
            {label}
          </div>
        </div>
      );
    }
}

export default CorrectButton;
