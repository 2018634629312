import React from "react";

function CoverModel(props) {
  if (props.excluded.includes(props.productType)) {
    return "";
  }
  const redTemplate = (value) => {
    return (<div className="coverModelWithWindowOrEmpty">
      <b>{value}</b>
    </div>);
  };
  if (props.value == null || props.value !== "Nie") {
    return redTemplate(props.value ? props.value : <div className="redBox"/>);
  }
  return <div>{props.value}</div>;
}

export default CoverModel;
