import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import { unauthorize } from "../actions";
import { connect } from "react-redux";
import { BACKEND_URL } from "../constants";
import NewCompleteButton from "../components/NewCompleteButton";
import PrevButton from "../components/PrevButton";
import NextButton from "../components/NextButton";
import Paging from "../components/Paging";
import QuantitySelect from "../components/QuantitySelect";
import EditCommentModal from "../components/Modal/EditCommentModal";
import editIcon from "../images/edit_icon.png";

class NewPersonalizationTable extends Component {
  constructor(props) {
    super(props);
    this.state = initState;
    this.setCommentEditText = this.setCommentEditText.bind(this);
  }

  searchInputKeyboardShortcut = function (e) {
    if (e.key === "/") {
      document.getElementById("searchInput").value = "";
      document.getElementById("searchInput").focus();
    }
  };

  componentDidMount() {
    this.getData();
    setInterval(
      function () {
        this.getData();
      }.bind(this),
      60 * 1000
    );
    window.addEventListener("keyup", this.searchInputKeyboardShortcut, false);
  }

  componentWillUnmount() {
    window.removeEventListener("keypress", this.searchInputKeyboardShortcut);
  }

  getData = () => {
    const that = this;
    const quantity = this.state.quantity;
    const offset = this.state.offset;
    const searchPhrase = this.state.searchPhrase;
    const showArchived = this.state.showArchived;

    let resource = searchPhrase
      ? "searchData"
      : showArchived
      ? "archiveData"
      : "productionData";

    fetch(
      BACKEND_URL +
        "/newPersonalization/" +
        resource +
        "?quantity=" +
        quantity +
        "&offset=" +
        offset +
        (searchPhrase ? "&orderNumberPattern=" + searchPhrase : ""),
      {
        credentials: "include",
      }
    )
      .then((res) => {
        if (res.status === 403 || res.status === 401) {
          throw Error("Response status code " + res.status);
        }
        return res;
      })
      .then((res) => res.json())
      .then((res) => {
        that.setState({
          personalization: res.data,
          counter: res.counter,
        });
      })
      .catch((err) => {
        console.error(err);
        that.props.unauthorize();
      });
  };

  getOrderUrl = (element) => {
    return (
      "https://panel.crystal-albums.pl/laborders/orders/edit/order_id/" +
      element.orderNumber
    );
  };

  getAlbumOrderUrl = (element) => {
    return (
      "https://panel.crystal-albums.pl/laborders/albumorders/details/order_id/" +
      element.orderNumber +
      "/album_order_config_id/" +
      element.albumOrderNumber
    );
  };

  setQuantity = (event) => {
    let value = event.target.value;
    this.setState(
      {
        quantity: parseInt(value), //FIXME
        offset: 0,
      },
      () => this.getData()
    );
  };

  logout = () => {
    let that = this;
    fetch(BACKEND_URL + "/logout", { credentials: "include" })
      .then(() => that.props.unauthorize())
      .catch((err) => console.log("Logout error", err));
  };

  switchShowArchived = () => {
    this.setState(
      {
        showArchived: !this.state.showArchived,
        offset: 0,
      },
      () => this.getData()
    );
  };

  search = (event) => {
    const phrase = event.target.value;
    this.setState(
      {
        searchPhrase: phrase,
        offset: 0,
      },
      () => this.getData()
    );
  };

  markIfEmpty = (value) => {
    return value ? value : <div className="redBox" />;
  };

  getOrNoData = (element, label) => {
    return (
      <div className={element ? "" : "personalizationPropertyNotFound"}>
        {label}:&nbsp;{element ? element : "nie podano"}
      </div>
    );
  };

  showPersonalizationProperties = (index) => {
    const el = document.getElementById("personalizationProperties" + index);
    el.classList.toggle("show");
    const elButton = document.getElementById("showHideButton" + index);
    elButton.innerText = elButton.classList.contains("show")
      ? "Rozwiń"
      : "Zwiń";
  };

  showAll = () => {
    const els = document.querySelectorAll('[id^="personalizationProperties"]');
    els.forEach((el) => {
      if (!el.classList.contains("show")) {
        el.classList.add("show");
      }
    });

    const elsButtons = document.querySelectorAll('[id^="showHideButton"]');
    elsButtons.forEach((el) => {
      el.innerText = "Zwiń";
    });
  };

  hideAll = () => {
    const els = document.querySelectorAll('[id^="personalizationProperties"]');
    els.forEach((el) => {
      if (el.classList.contains("show")) {
        el.classList.remove("show");
      }
    });

    const elsButtons = document.querySelectorAll('[id^="showHideButton"]');
    elsButtons.forEach((el) => {
      el.innerText = "Rozwiń";
    });
  };

  mapMediasToButtons = (media, index) => {
    return (
      <div key={index}>
        <a href={BACKEND_URL + "/personalization/file/" + media.id}>
          Pobierz załącznik - {media.parameterName}
        </a>
      </div>
    );
  };

  mapDetailsToRow = (details) => {
    let redBox;
    if (details.invalid) {
      redBox = <div className="redBox inlineBlock"/>;
    }

    if (
      (details.key === "Własna grafika" || details.key === "Plik") &&
      details.value != null
    ) {
      return (
        <tr>
          <td>
            {details.key}:{" "}
            <a href={BACKEND_URL + "/personalization/file/" + details.value}>
              Pobierz
            </a>
          </td>
        </tr>
      );
    } else if (
      (details.key === "Własna grafika" || details.key === "Plik") &&
      details.value == null
    ) {
      return (
        <tr>
          <td>
            {details.key}:{" "}  {redBox}
          </td>
        </tr>
      );
    }

    return (
      <tr key={details.key}>
        <td>
          {details.key}: {details.value} 
          {redBox}
        </td>
      </tr>
    );
  };

  mapElementToRow = (element, index) => {
    const completeButton = this.state.showArchived ? (
      ""
    ) : (
      <NewCompleteButton
        id={element.id}
        productType={element.productType}
        reloadData={this.getData}
        color={element.batchColor}
      />
    );

    return (
      <tr
        key={index}
        className={element.status === "Poprawka" ? "inCorrection" : null}>
        <td
          width="5%"
          style={{ background: element.batchColor }}
          className={element.archived ? "archivedRow" : null}>
          {" "}
          {/*FIXME width*/}
          <a
            href={this.getOrderUrl(element)}
            target="_blank"
            rel="noopener noreferrer"
            className="tableLink">
            {element.orderNumber}
          </a>
        </td>
        <td width="5%" className={element.archived ? "archivedRow" : null}>
          {" "}
          {/*FIXME width*/}
          <a
            href={this.getAlbumOrderUrl(element)}
            target="_blank"
            rel="noopener noreferrer"
            className="tableLink">
            {element.albumOrderNumber}
          </a>
        </td>
        <td width="50%">
          <div>{element.orderNumber} {element.name}</div>
          <div className="personalizationComment">{element.comment}</div>
          <div className="comment">
            <button className="btnLink" onClick={() => this.showModal(element.id, element.caComment)}>
              <img src={editIcon} className="editIcon" alt="Edytuj komentarz"/>
            </button>
            {element.caComment}
          </div>
          <div
            className="personalizationProperties"
            id={"personalizationProperties" + index}>
            <div style={{ display: "flex" }}>
              <table style={{ width: "270px" }}>
                <thead>
                  <tr>
                    <td colSpan={2}>Szczegóły</td>
                  </tr>
                </thead>
                <tbody>
                {element.details.map((element, index) =>
                  this.mapDetailsToRow(element)
                )}
                </tbody>
              </table>
              &nbsp;
              <table style={{ width: "270px" }}>
                <thead>
                  <tr>
                    <td colSpan={2}>Front</td>
                  </tr>
                </thead>
                <tbody>
                {element.front.map((element, index) =>
                  this.mapDetailsToRow(element)
                )}
                </tbody>
              </table>
              &nbsp;
              <table style={{ width: "270px" }}>
                <thead>
                  <tr>
                    <td colSpan={2}>Tył</td>
                  </tr>
                </thead>
                <tbody>
                {element.back.map((element, index) =>
                  this.mapDetailsToRow(element)
                )}
                </tbody>
              </table>
            </div>
          </div>
        </td>
        <td width="10%">{this.markIfEmpty(element.type)}</td>
        <td width="5%">{this.markIfEmpty(element.format)}</td>
        {/* <td width="10%">{this.markIfEmpty(element.personalizationType)}</td> */}
        <td width="10%">{element.personalizationType}</td>
        <td width="5%">{element.quantity}</td>
        <td width="12%">
          <div style={{ display: "flex" }}>
            {/* <button onClick={() => this.handleOpenModal(element.id, element.comment, element.productType)}>Edycja komentarza</button> */}
            <div className="personalizationTableButtons">
              <div
                className="printButton"
                onClick={() => this.showPersonalizationProperties(index)}>
                <div className="printButtonInner" id={"showHideButton" + index}>
                  Rozwiń
                </div>
              </div>
              {completeButton}
            </div>
          </div>
        </td>
      </tr>
    );
  };

  setOffsetAndRefresh = (newOffset) => {
    this.setState(
      {
        offset: newOffset,
      },
      () => this.getData()
    );
  };

  setCommentEditText = (event) => {
    this.setState({
      commentEditText: event.target.value,
    });
  };

  showModal = (id, comment) => {
    this.setState({
      showModal: true,
      commentEditId: id,
      commentEditText: comment
    });
  }

  handleCloseModal = (saveComment) => {
    this.setState({
      showModal: false
    });

    if (saveComment) {
      this.saveComment();
    }
  }

  handleChangeComment = (comment) => {
    this.setState({
      commentEditText: comment
    });
  }

  saveComment = () => {
    let that = this;
    const requestOption = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ comment: this.state.commentEditText }),
      credentials: "include"
    };

    fetch(BACKEND_URL + `/newPersonalization/comment/${this.state.commentEditId}`, requestOption)
      .then((res) => {
        if (res.status === 403 || res.status === 401) {
          throw Error("Response status code " + res.status);
        }
      })
      .then(() => {
        this.setState({
          commentEditId: initState.commentEditId,
          commentEditText: initState.commentEditText,
        }, () => this.getData());
      })
      .catch(err => {
        console.error(err);
        that.props.unauthorize();
      });
  }

  render() {
    if (!this.props.authorized) {
      return <Redirect to="/login" />;
    }
    const quantity = this.state.quantity;
    const showArchivedButton = (
      <button onClick={this.switchShowArchived}>
        {this.state.showArchived ? "Produkcja" : "Archiwum"}
      </button>
    );
    return (
      <div>
        <div className="tablePageTopMenu">
          <button onClick={this.logout}>Wyloguj</button>
          <button onClick={() => this.props.history.push("/")}>Powrót</button>
          {showArchivedButton}
          <input
            type="text"
            value={this.state.searchPhrase}
            onChange={this.search}
            placeholder="Wyszukaj"
            className="searchInput"
            id="searchInput"
            ref={this.textInput}
          />
          <div className="grow" />
          <button onClick={this.hideAll}>Zwiń wszystkie</button>
          <button onClick={this.showAll}>Rozwiń wszystkie</button>
          <Paging
            quantity={quantity}
            offset={this.state.offset}
            counter={this.state.counter}
          />
          <PrevButton
            quantity={quantity}
            offset={this.state.offset}
            callback={this.setOffsetAndRefresh}
          />
          <NextButton
            quantity={quantity}
            offset={this.state.offset}
            counter={this.state.counter}
            callback={this.setOffsetAndRefresh}
          />
        </div>
        <div className="coversTable">
          <table>
            <thead>
              <tr>
                <td>Nr zlecenia</td>
                <td>Nr podzlecenia</td>
                <td>Nazwa</td>
                <td>Typ</td>
                <td>Format</td>
                <td>Typ personalizacji</td>
                <td>Ilość</td>
                <td>Opcje</td>
              </tr>
            </thead>
            <tbody>
              {this.state.personalization.map((element, index) =>
                this.mapElementToRow(element, index)
              )}
            </tbody>
          </table>
        </div>
        <div className="tablePageBottomMenu">
          <div className="grow" />
          <QuantitySelect
            counter={this.state.counter}
            setQuantity={this.setQuantity}
          />
          <EditCommentModal
            isModalOpen={this.state.showModal}
            close={this.handleCloseModal}
            onChangeComment={this.handleChangeComment}
            comment={this.state.commentEditText} />
        </div>
      </div>
    );
  }
}

const initState = {
  personalization: [],
  quantity: 10,
  offset: 0,
  searchPhrase: "",
  counter: 0,
  showArchived: false,
  showModal: false,
  commentEditId: 0,
  commentEditText: "",
};

const mapStateToProps = (state) => {
  return {
    authorized: state.authorized,
  };
};

const mapDispatchToProps = { unauthorize };

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NewPersonalizationTable);
