import React, {Component} from "react";

class PrevButton extends Component {

    showPreviousElements = () => {
      const pretendingOffset = this.props.offset - this.props.quantity;
      const newOffset = pretendingOffset < 0 ? 0 : pretendingOffset;
      const oldOffset = this.props.offset;
      if (oldOffset !== newOffset) {
        this.props.callback(newOffset);
      }
    }

    render() {
      return (
        <button onClick={this.showPreviousElements}>-{this.props.quantity}</button>
      );
    }
}

export default PrevButton;