import React, {Component} from "react";
import {HashRouter, Route} from "react-router-dom";
import BlocksTable from "./BlocksTable";
import BlocksNumpad from "./BlocksNumpad";

class BlocksPage extends Component {

  render() {
    return (<HashRouter>
      <Route exact path="/blocks/table/:orderNumber?" component={BlocksTable}/>
      <Route exact path="/blocks/numpad" component={BlocksNumpad}/>
    </HashRouter>
    );
  }
}

export default BlocksPage;