import React, { Component } from "react";
import { storeCrystalBoardsTablePage, unauthorize } from "../actions";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { BACKEND_URL } from "../constants";
import CrystalBoardsPrintButton from "../components/CrystalBoardsPrintButton";
import Paging from "../components/Paging";
import PrevButton from "../components/PrevButton";
import NextButton from "../components/NextButton";
import QuantitySelect from "../components/QuantitySelect";

class CrystalBoardsPackageTable extends Component {

  constructor(props) {
    super(props);
    this.state = initState;
  }

  componentDidMount() {
    this.getData();
    setInterval(
      function () {
        this.getData();
      }.bind(this),
      60 * 1000
    );
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.offset !== this.props.offset
            || prevProps.counter !== this.props.counter
            || prevProps.quantity !== this.props.quantity) {
      this.getData();
    }
  }

  componentWillUnmount() {
    this.setState(initState);
  }

    getData = () => {
      const that = this;
      const quantity = this.props.quantity;
      const offset = this.props.offset;
      const searchPhrase = this.state.searchPhrase;
      const showArchived = this.state.showArchived;
      fetch(BACKEND_URL + "/crystalboards?quantity=" + quantity +
            "&offset=" + offset +
            (searchPhrase ? "&orderNumberPattern=" + searchPhrase : "") +
            (showArchived ? "&showArchived=true" : ""), {
        credentials: "include"
      })
        .then((res) => {
          if (res.status === 403 || res.status === 401) {
            throw Error("Response status code " + res.status);
          }
          return res;
        })
        .then(res => res.json())
        .then(res => {
          that.setState({
            crystalBoards: res.data,
          }, () => {
            that.props.storeCrystalBoardsTablePage({counter: res.counter});
          });
        })
        .catch(err => {
          console.error(err);
          that.props.unauthorize();
        });
    }

    setQuantity = (event) => {
      this.props.storeCrystalBoardsTablePage({
        quantity: parseInt(event.target.value)
      });
    }

    search = (event) => {
      const phrase = event.target.value;
      this.setOffsetAndRefresh(0);
      this.setState({
        searchPhrase: phrase,
        offset: 0
      }, () => this.getData());
    }

    getOrderUrl = element => {
      return "https://panel.crystal-albums.pl/laborders/orders/edit/order_id/" + element.orderNumber;
    }

    getAlbumOrderUrl = (element) => {
      return "https://panel.crystal-albums.pl/laborders/albumorders/details/order_id/" +
            element.orderNumber + "/album_order_config_id/" + element.albumOrderNumber;
    }

    orderId = (orderId) => {
      return <div className="orderId">
            SP {orderId}
      </div>;
    }

    switchShowArchived = () => {
      this.setState({showArchived: !this.state.showArchived}, () => {
        if (this.props.offset === 0) {
          this.getData();
        } else {
          this.setOffsetAndRefresh(0);
        }
      });
    }

    setOffsetAndRefresh = (newOffset) => {
      this.props.storeCrystalBoardsTablePage({
        offset: newOffset
      });
    }

    getHooks = (hooks) => {
      if (hooks) {
        return "TAK";
      }
      return "-";
    }

    render() {
      if (!this.props.authorized) {
        return (
          <Redirect to="/login" />
        );
      }
      const quantity = this.props.quantity;
      const showArchivedButton = <button
        onClick={this.switchShowArchived}>{this.state.showArchived ? "Produkcja" : "Archiwum"}</button>;
      return (<div>
        <div className="tablePageTopMenu">
          <button onClick={this.logout}>Wyloguj</button>
          <button onClick={() => this.props.history.push("/")}>Powrót</button>
          {showArchivedButton}
          <input type="text" onChange={this.search} placeholder="Wyszukaj" className="searchInput"/>
          <div className="grow" />
          <Paging quantity={quantity} offset={this.props.offset} counter={this.props.counter} />
          <PrevButton quantity={quantity} offset={this.props.offset} callback={this.setOffsetAndRefresh} />
          <NextButton quantity={quantity} offset={this.props.offset} counter={this.props.counter}
            callback={this.setOffsetAndRefresh} />
        </div>

        <div className="coversTable">
          <table>
            <thead>
              <tr>
                <td>Nr</td>
                <td>Nazwa</td>
                <td>Ilość</td>
                <td>Haczyki</td>
                <td>Wydrukowane</td>
                <td>Opcje</td>
              </tr>
            </thead>
            <tbody>
              {this.state.crystalBoards.map((element, index) => (
                <tr key={index}>
                  <td width="10%" className={element.archived ? "archivedRow" : null}> {/*FIXME width*/}
                    <a href={this.getOrderUrl(element)} target="_blank" rel="noopener noreferrer"
                      className="tableLink">
                      {element.orderNumber}
                    </a>{element.externalOrderId != null ? this.orderId(element.externalOrderId) : ""}
                  </td>
                  <td width="40%">
                    <div>
                      <a href={this.getAlbumOrderUrl(element)} target="_blank"
                        rel="noopener noreferrer" className="tableLink">
                        {element.name}
                      </a>
                    </div>
                  </td>
                  <td width="10%">{element.quantity}</td>
                  <td width="10%">{this.getHooks(element.hooks)}</td>
                  <td width="10%">BD</td>
                  <td width="20%">
                    <div className="crystalBoardsTableButtons">
                      <CrystalBoardsPrintButton id={element.id} reloadData={this.getData} archive={element.archived} />
                      <div className="grow" />
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div className="tablePageBottomMenu">
          <div className="grow"/>
          <QuantitySelect counter={this.props.counter} setQuantity={this.setQuantity}/>
        </div>
      </div>);
    }
}

const initState = {
  crystalBoards: [],
  searchPhrase: "",
  showArchived: false
};

const mapStateToProps = (state) => {
  return {
    authorized: state.authorized,
    ...state.tablePage.crystalBoardsTablePage
  };
};

const mapDispatchToProps = { unauthorize, storeCrystalBoardsTablePage };

export default connect(mapStateToProps, mapDispatchToProps)(CrystalBoardsPackageTable);
