import React, {Component} from "react";

class BlocksNumpad extends Component {

  constructor(props) {
    super(props);
    this.state = initState;
  }

  componentDidMount() {
    document.addEventListener("keydown", this._handleKeyDown);
  }

    _handleKeyDown = (event) => {
      let charList = "0123456789";
      const key = event.key;
        
      if (charList.indexOf(key) === -1) return;

      const currentTime = Date.now();

      if (currentTime - this.state.lastKeyTime > 100) {
        let newBuffer = key;
        this.setState({
          buffer: newBuffer,
          lastKeyTime: Date.now()
        });
      } else {
        let newBuffer = this.state.buffer + key;
        this.setState({
          buffer: newBuffer,
          lastKeyTime: Date.now()
        }); 
      }
      if (this.state.buffer.length === 6) {
        this.setState({
          orderNumber: this.state.buffer
        });
        this.props.history.push("/blocks/table/" + this.state.orderNumber);
      }
    }

    addDigit = (digit) => {
      this.setState({
        orderNumber: this.state.orderNumber + "" + digit
      });
    }

    removeDigit = () => {
      this.setState({
        orderNumber: this.state.orderNumber.substring(0, this.state.orderNumber.length - 1)
      });
    }

    clearOrderNumber = () => {
      this.setState({
        orderNumber: initState.orderNumber
      });
    }

    render() {
      const orderNumberLabel = this.state.orderNumber ? " " + this.state.orderNumber : "";
      return (
        <div className="numpadContainer">
          <div className="numpadRow">
            <div className="numpadButton grow3 numpadDark" onClick={() => this.props.history.push("/blocks/table/" + this.state.orderNumber)}>
              <div className="numpadButtonInner">Wyszukaj{orderNumberLabel}</div>
            </div>

          </div>
          <div className="numpadRow">
            <div className="numpadButton numpadDark" onClick={this.removeDigit}>
              <div className="numpadButtonInner">Usuń</div>
            </div>
            <div className="numpadButton" onClick={() => this.addDigit(1)}>
              <div className="numpadButtonInner">1</div>
            </div>
            <div className="numpadButton" onClick={() => this.addDigit(2)}>
              <div className="numpadButtonInner">2</div>
            </div>
            <div className="numpadButton" onClick={() => this.addDigit(3)}>
              <div className="numpadButtonInner">3</div>
            </div>
          </div>
          <div className="numpadRow">
            <div className="numpadButton numpadDark" onClick={this.clearOrderNumber}>
              <div className="numpadButtonInner">Wyczyść</div>
            </div>
            <div className="numpadButton" onClick={() => this.addDigit(4)}>
              <div className="numpadButtonInner">4</div>
            </div>
            <div className="numpadButton" onClick={() => this.addDigit(5)}>
              <div className="numpadButtonInner">5</div>
            </div>
            <div className="numpadButton" onClick={() => this.addDigit(6)}>
              <div className="numpadButtonInner">6</div>
            </div>
          </div>
          <div className="numpadRow">
            <div className="numpadButton grow0 numpadDark" onClick={() => this.props.history.push("/")}>
              <div className="numpadButtonInner">Powrót</div>
            </div>
            <div className="numpadButton" onClick={() => this.addDigit(7)}>
              <div className="numpadButtonInner">7</div>
            </div>
            <div className="numpadButton" onClick={() => this.addDigit(8)}>
              <div className="numpadButtonInner">8</div>
            </div>
            <div className="numpadButton" onClick={() => this.addDigit(9)}>
              <div className="numpadButtonInner">9</div>
            </div>
          </div>
          <div className="numpadRow">
            <div className="grow1"/>
            <div className="numpadButton grow2" onClick={() => this.addDigit(0)}>
              <div className="numpadButtonInner">0</div>
            </div>
          </div>
        </div>
      );
    }
}

const initState = {
  orderNumber: "",
  lastKeyTime: null,
  buffer: ""
};

export default BlocksNumpad;
