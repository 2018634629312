export const authorized = (state = initState, action) => {
  switch (action.type) {
    case "AUTHORIZATION":
      saveAuthorizationInCookie(action.value);
      return action.value;
    default:
      return state;
  }
};

const saveAuthorizationInCookie = (value) => {
  document.cookie = "authorization=" + value + "; SameSite=None; Secure";
};

const calculateInitState = () => {
  const cookies = document.cookie.split(";");
  const authorizationCookie = cookies
    .map((cookie) => cookie.trim())
    .find((cookie) => cookie.startsWith("authorization="));
  if (!authorizationCookie) {
    return false;
  }
  return authorizationCookie.substr("authorization=".length) === "true";
};

const initState = calculateInitState();
