import React, {Component} from "react";
import {NavLink} from "react-router-dom";

class BatchColorButton extends Component {
  render() {
    const destinationLink = "/covers/batchColors";
    return (
      <NavLink to={destinationLink}>
        <button >Kolor partii</button>
      </NavLink>
    );
  }
}

export default BatchColorButton;