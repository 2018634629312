import React, {Component} from "react";


class Paging extends Component {

    paging = () => {
      const offset = this.props.offset;
      const quantity = this.props.quantity;
      const counter = this.props.counter;
      const pages = Math.ceil(counter / quantity);
      const page = pages - Math.ceil((counter - offset) / quantity) + 1;
      const pageLabel = <div>
            strona {page} / {pages}
      </div>;
      if (offset + quantity + 1 > counter) {
        return <div>
          {offset + 1} - {counter} / {counter}
          {pageLabel}
        </div>;
      }
      return <div>
        {offset + 1} - {offset + quantity} / {counter}
        {pageLabel}
      </div>;
    }

    render() {
      return (
        <div>
          <div className="info">
            {this.paging()}
          </div>
        </div>
      );
    }
}

export default Paging;