import React, {Component} from "react";
import {BACKEND_URL} from "../constants";
import {unauthorize} from "../actions";
import {connect} from "react-redux";
import {Redirect} from "react-router-dom";

class EditCover extends Component {

  constructor(props) {
    super(props);
    this.state = initState;
  }

  componentDidMount() {
    this.getData();
  }

    getData = () => {
      if (this.props.match?.params?.id) {
        let that = this;
        fetch(BACKEND_URL + "/covers/" + this.props.match.params.id, {
          credentials: "include"
        })
          .then((res) => {
            if (res.status === 403 || res.status === 401) {
              throw Error("Response status code " + res.status);
            }
            return res;
          })
          .then(res => res.json())
          .then((res => this.setState({
            tableEntry: res
          })))
          .catch(err => {
            console.error(err);
            that.props.unauthorize();
          });
      }
    }

    handleGenericChange = (field, value) => {
      this.setState({
        tableEntry: Object.assign({}, this.state.tableEntry, {
          [field]: value
        })
      });
    }

    saveAndExit = () => {
      let that = this;
      if (this.props.match?.params?.id) {
        fetch(BACKEND_URL + "/covers/update", {
          method: "POST",
          headers: {
            "Content-Type": "application/json"
          },
          body: JSON.stringify(this.state.tableEntry),
          credentials: "include"
        })
          .then((res) => {
            if (res.status === 403 || res.status === 401) {
              throw Error("Response status code " + res.status);
            }
          })
          .then(() => {
            that.props.history.push("/covers");
          })
          .catch(err => {
            console.error(err);
            that.props.unauthorize();
          });
      }
    }

    reset = () => {
      this.getData();
    }

    reimport = () => {
      let that = this;
      that.setState({
        tableEntry: initState.tableEntry
      });
      if (this.props.match?.params?.id) {
        fetch(BACKEND_URL + "/covers/reimport/" + this.props.match.params.id, {
          method: "POST",
          credentials: "include"
        })
          .then((res) => {
            if (res.status === 403 || res.status === 401) {
              throw Error("Response status code " + res.status);
            }
            this.getData();
          })
          .catch(err => {
            console.error(err);
            that.props.unauthorize();
          });
      }
    }

    makeButton = (label, action) => {
      return (<div className="opButton" onClick={action}>
        <div className="opButtonInner">
          {label}
        </div>
      </div>);
    }

    render() {
      if (!this.props.authorized) {
        return (
          <Redirect to="/login"/>
        );
      }

      const cancelButton = this.makeButton("Wróć", () => this.props.history.push("/covers"));
      const resetButton = this.makeButton("Cofnij zmiany do ostatniej zapisanej wersji", this.reset);
      const reimportButton = this.makeButton("Pobierz ponownie z panelu i zapisz", this.reimport);
      const saveAndExitButton = this.makeButton("Zapisz i wróć", this.saveAndExit);
      return (
        <div className="editCoverContainer">
          <div className="editCoverContainerTitle">
                    Zamówienie {this.state.tableEntry.orderNumber} / {this.state.tableEntry.albumOrderNumber}
          </div>
          <div className="editCoverContainerRow">
            <div className="editCoverContainerColumn">
              <label htmlFor="suborderName">Nazwa</label><br/>
              <input id="suborderName" name="suborderName" type="text"
                value={this.state.tableEntry.suborderName}
                onChange={(event) => this.handleGenericChange("suborderName", event.target.value)}/>
              <br/><br/>
              <label htmlFor="productType">Typ</label><br/>
              <select id="productType" name="productType" value={this.state.tableEntry.productType}
                onChange={(event) => this.handleGenericChange("productType", event.target.value)}>
                <option/>
                <option>Fotoalbum</option>
                <option>Storybook</option>
                <option>Tradycyjny</option>
                <option>Opakowanie Pendrive</option>
                <option>Opakowanie 1 CD</option>
                <option>Opakowanie 2 CD</option>
                <option>Opakowanie 3 CD</option>
                <option>Opakowanie 4 CD</option>
                <option>Opakowanie Odbitki</option>
                <option>Fotoalbum dziecięcy</option>
                <option>Opakowanie Album</option>
              </select>
              <br/><br/>
              <label htmlFor="format">Format</label><br/>
              <select id="format" name="format" value={this.state.tableEntry.format}
                onChange={(event) => this.handleGenericChange("format", event.target.value)}>
                <option/>
                <option>10x10</option>
                <option>15x15</option>
                <option>20x20</option>
                <option>20x30</option>
                <option>25x25</option>
                <option>25x35</option>
                <option>30x30</option>
                <option>25x25 TRAD</option>
                <option>30x30 TRAD</option>
                <option>33x33 TRAD</option>
                <option>Pudełko na CD</option>
                <option>Pudełko na Pendrive</option>
                <option>Pudełko na Odbitki</option>
                <option>20x20 do 25</option>
                <option>25x25 do 25</option>
                <option>30x30 do 25</option>
                <option>20x20 do 35</option>
                <option>25x25 do 35</option>
                <option>30x30 do 35</option>
                <option>20x28</option>
              </select>
              <br/><br/>
              <label htmlFor="numberOfCards">Ilość kart</label><br/>
              <input type="text" id="numberOfCards" name="numberOfCards"
                value={this.state.tableEntry.numberOfCards}
                onChange={(event) => this.handleGenericChange("numberOfCards", event.target.value)}/>
              <br/><br/>
              <label htmlFor="additionalCard">Rozbiegówka</label><br/>
              <input type="checkbox" id="additionalCard" name="additionalCard"
                checked={this.state.tableEntry.additionalCard}
                onChange={(event) => this.handleGenericChange("additionalCard", event.target.checked)}/>
              <br/><br/>
              <label htmlFor="materialName">Materiał</label><br/>
              <input type="text" id="materialName" name="materialName"
                value={this.state.tableEntry.materialName}
                onChange={(event) => this.handleGenericChange("materialName", event.target.value)}/>
            </div>

            <div className="editCoverContainerColumnSeparator"/>

            <div className="editCoverContainerColumn">
                        Okładka<br/>
              <div className="radioOptions">
                <div>
                  <input type="radio" id="coverType_empty" name="coverType" value=""
                    checked={this.state.tableEntry.coverType === ""}
                    onChange={(event) => this.handleGenericChange("coverType", event.target.value)}/>
                  <label htmlFor="coverType_empty">brak</label>
                </div>
                <div>
                  <input type="radio" id="coverType_tough" name="coverType" value="twarda"
                    checked={this.state.tableEntry.coverType === "twarda"}
                    onChange={(event) => this.handleGenericChange("coverType", event.target.value)}/>
                  <label htmlFor="coverType_tough">twarda</label>
                </div>
                <div>
                  <input type="radio" id="coverType_sponge" name="coverType" value="z gąbką"
                    checked={this.state.tableEntry.coverType === "z gąbką"}
                    onChange={(event) => this.handleGenericChange("coverType", event.target.value)}/>
                  <label htmlFor="coverType_sponge">z gąbką</label>
                </div>
              </div>
              <br/><br/>

                        Typ personalizacji<br/>
              <div className="radioOptions">
                <div>
                  <input type="radio" id="personalizationType_empty" name="personalizationType"
                    value="brak"
                    checked={this.state.tableEntry.personalizationType === "brak"}
                    onChange={(event) => this.handleGenericChange("personalizationType", event.target.value)}/>
                  <label htmlFor="personalizationType_empty">brak</label>
                </div>
                <div>
                  <input type="radio" id="personalizationType_engraver" name="personalizationType"
                    value="grawer"
                    checked={this.state.tableEntry.personalizationType === "grawer"}
                    onChange={(event) => this.handleGenericChange("personalizationType", event.target.value)}/>
                  <label htmlFor="personalizationType_engraver">grawer</label>
                </div>
                <div>
                  <input type="radio" id="personalizationType_uv" name="personalizationType"
                    value="nadruk UV"
                    checked={this.state.tableEntry.personalizationType === "nadruk UV"}
                    onChange={(event) => this.handleGenericChange("personalizationType", event.target.value)}/>
                  <label htmlFor="personalizationType_uv">nadruk UV</label><br/><br/>
                </div>
              </div>
              <br/><br/>
              <label htmlFor="ridge">Grzbiet</label><br/>
              <input type="text" id="ridge" name="ridge" value={this.state.tableEntry.ridge}
                onChange={(event) => this.handleGenericChange("ridge", event.target.value)}/>
              <br/><br/>
              <label htmlFor="quantity">Ilość</label><br/>
              <input type="text" id="quantity" name="quantity" value={this.state.tableEntry.quantity}
                onChange={(event) => this.handleGenericChange("quantity", event.target.value)}/>
              <br/><br/>
              <label htmlFor="coverModel">Model okładki</label><br/>
              <input type="text" id="coverModel" name="coverModel" value={this.state.tableEntry.coverModel}
                onChange={(event) => this.handleGenericChange("coverModel", event.target.value)}/>
              <br/><br/>
              <label htmlFor="comment">Komentarz</label><br/>
              <textarea id="comment" name="comment" value={this.state.tableEntry.comment}
                onChange={(event) => this.handleGenericChange("comment", event.target.value)}
                cols="59"/>
            </div>
          </div>
          <div className="editCoverContainerButtons">
            {cancelButton}
            {resetButton}
            {reimportButton}
            {saveAndExitButton}
          </div>
        </div>
      );
    }
}

const initState = {
  tableEntry: {
    suborderName: "",
    productType: "",
    format: "",
    numberOfCards: "",
    additionalCard: false,
    materialName: "",
    coverType: "",
    personalizationType: "",
    ridge: "",
    quantity: "",
    coverModel: "",
    comment: ""
  }
};

const mapStateToProps = (state) => {
  return {
    authorized: state.authorized
  };
};

const mapDispatchToProps = {unauthorize};

export default connect(mapStateToProps, mapDispatchToProps)(EditCover);
