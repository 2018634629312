import React, {Component} from "react";
import {NavLink} from "react-router-dom";

class EditButton extends Component {
  render() {
    const editLink = "/covers/edit/" + this.props.id;
    return (
      <NavLink to={editLink}>
        <div className="editButton">
                    Edytuj
        </div>
      </NavLink>
    );
  }
}

export default EditButton;