import React, { Component } from "react";
import { BACKEND_URL } from "../constants";

class CompleteButton extends Component {
  complete = () => {
    if (this.props.id) {
      fetch(
        BACKEND_URL +
          "/personalization/complete/" +
          this.props.id +
          "/" +
          this.props.productType,
        {
          credentials: "include",
          method: "POST",
        }
      ).then(() => {
        if (this.props.reloadData) {
          this.props.reloadData();
        }
      });
    }
  };

  render() {
    return (
      <div className="printButton highlightedButton" onClick={this.complete}>
        <div className="printButtonInner">Zakończ</div>
      </div>
    );
  }
}

export default CompleteButton;
